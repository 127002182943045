import { ValidationProblemDetails, ProblemDetailsException } from "Models";
import { getCurrentUserToken } from "modules/user";
import {
  createProblemDetailsSchema,
  responseWithErrorsSchema,
  problemDetailsSchema,
  validationProblemDetailsSchema,
} from "entities/common";
import axios from "axios";
import { z } from "zod";

export const clubhouseApiHeadersV1 = (): {
  headers: { authorization: string; "API-Key": string };
} => ({
  headers: {
    authorization: `Bearer ${getCurrentUserToken()}`,
    "API-Key": `${process.env.REACT_APP_API_KEY}`,
  },
});

export const clubhouseApiHeadersV2 = (): {
  headers: {
    authorization: string;
    "Ocp-Apim-Subscription-Key": string;
    "x-api-version": string;
  };
} => ({
  headers: {
    authorization: `Bearer ${getCurrentUserToken()}`,
    "Ocp-Apim-Subscription-Key": process.env.REACT_APP_APIM_KEY || "",
    "x-api-version": "2.0",
  },
});

export const clubhouseApiHeadersMultiPart = (): {
  headers: {
    authorization: string;
    "Ocp-Apim-Subscription-Key": string;
    "x-api-version": string;
    "Content-Type": string;
  };
} => ({
  headers: {
    authorization: `Bearer ${getCurrentUserToken()}`,
    "Ocp-Apim-Subscription-Key": process.env.REACT_APP_APIM_KEY || "",
    "x-api-version": "2.0",
    "Content-Type": "multipart/form-data",
  },
});

export const lockerApiHeaders = (): {
  headers: {
    authorization: string;
    "Ocp-Apim-Subscription-Key": string;
    "x-api-version": string;
    "API-Key": string;
  };
} => ({
  headers: {
    authorization: `Bearer ${getCurrentUserToken()}`,
    "Ocp-Apim-Subscription-Key": process.env.REACT_APP_APIM_KEY || "",
    "x-api-version": "2.0",
    "API-Key": `${process.env.REACT_APP_API_KEY_LOCKER}`,
  },
});

export enum ApiResponseErrorType {
  ValidationException = "ValidationException",
  ProblemDetailsException = "ProblemDetailsException",
}

export enum ApiExceptionClassType {
  SCORECARD_NOT_FINALIZED = "error.scorecardnotfinalizedexception",
}

export const isApiStatusSuccess = (status: number | undefined) => {
  return status === 200 || status === 201 || status === 202 ? true : false;
};

export const getApiResponseErrorMessage = (response: any) => {
  if (
    response.type === ApiResponseErrorType.ValidationException ||
    response?.errors
  ) {
    return (response as ValidationProblemDetails)?.errors[
      Object.keys(response?.errors)[0]
    ].toString();
  } else {
    return (response as ProblemDetailsException)?.detail;
  }
};

export async function fetchWithValidation<T>(
  url: string,
  schema: z.ZodType<T, z.ZodTypeDef, unknown>,
  config?: any
): Promise<
  | T
  | z.infer<typeof problemDetailsSchema>
  | z.infer<ReturnType<typeof responseWithErrorsSchema>>
> {
  const response = await axios.get(url, config);

  let parseSchema: z.ZodTypeAny;
  if (response.status === 200) {
    // Successful response
    parseSchema = schema;
  } else if (response.status === 207) {
    // multiple errors
    parseSchema = responseWithErrorsSchema(schema);
  } else if (response.status >= 400 && response.status < 600) {
    // For any other error responses, assume ProblemDetails
    parseSchema = createProblemDetailsSchema(schema);
  } else {
    // Fallback to responseSchema if status code is unexpected.
    parseSchema = schema;
  }

  // If validation fails, .parse() will throw an error.
  const parsed = parseSchema.safeParse(response.data);

  if (!parsed.success) {
    // If none of the schemas match, throw an error.
    console.log(`status: ${response.status} had these errors: `, parsed.error);
    throw new Error("Response did not match any expected schema.");
  }
  return parsed.data;
}

export async function deleteWithValidation(
  url: string,
  config?: any
): Promise<
  | z.infer<typeof problemDetailsSchema>
  | z.infer<ReturnType<typeof responseWithErrorsSchema>>
> {
  const schema = z.boolean();

  const response = await axios.delete(url, config);

  let parseSchema: z.ZodTypeAny;
  if (response.status === 200) {
    // Successful response
    parseSchema = responseWithErrorsSchema(schema);
  } else if (response.status >= 400 && response.status < 600) {
    // For any other error responses, assume ProblemDetails
    parseSchema = createProblemDetailsSchema(schema);
  } else {
    // Fallback to responseSchema if status code is unexpected.
    parseSchema = schema;
  }

  // If validation fails, .parse() will throw an error.
  const parsed = parseSchema.safeParse(response.data);

  if (!parsed.success) {
    // If none of the schemas match, throw an error.
    console.log(`status: ${response.status} had these errors: `, parsed.error);
    throw new Error("Response did not match any expected schema.");
  }
  return parsed.data;
}

export async function postWithValidation<TPayload, TResponse>(
  url: string,
  payloadSchema: z.ZodType<TPayload, z.ZodTypeDef, unknown>,
  payload: TPayload,
  responseSchema: z.ZodType<TResponse, z.ZodTypeDef, unknown>,
  config?: any
): Promise<
  | z.infer<typeof problemDetailsSchema>
  | z.infer<typeof validationProblemDetailsSchema>
  | z.infer<ReturnType<typeof responseWithErrorsSchema>>
> {
  // .parse() will throw a ZodError if validation fails.
  const validatedPayload = payloadSchema.parse(payload);

  const response = await axios.post(url, validatedPayload, config);
  // Determine the proper schema based on the status code.
  let parseSchema: z.ZodTypeAny;
  if (response.status === 200 || response.status === 201) {
    // Successful response
    parseSchema = responseWithErrorsSchema(responseSchema);
  } else if (response.status === 207) {
    // .NET–style response with errors
    parseSchema = responseWithErrorsSchema(responseSchema);
  } else if (response.status === 400) {
    // Could be standard ProblemDetails or ValidationProblemDetails
    parseSchema = z.union([
      problemDetailsSchema,
      validationProblemDetailsSchema,
    ]);
  } else if (response.status >= 400 && response.status < 600) {
    // For any other error responses, assume ProblemDetails
    parseSchema = problemDetailsSchema;
  } else {
    // Fallback to responseSchema if status code is unexpected.
    parseSchema = responseSchema;
  }

  // Validate the response data using the determined schema.
  const parsed = parseSchema.safeParse(response.data);

  if (!parsed.success) {
    // If none of the schemas match, throw an error.
    console.log(`status: ${response.status} had these errors: `, parsed.error);
    throw new Error("Response did not match any expected schema.");
  }
  return parsed.data;
}

export async function patchWithValidation<TPayload, TResponse>(
  url: string,
  payloadSchema: z.ZodType<TPayload, z.ZodTypeDef, unknown>,
  payload: TPayload,
  responseSchema: z.ZodType<TResponse, z.ZodTypeDef, unknown>,
  config?: any
): Promise<
  | z.infer<typeof problemDetailsSchema>
  | z.infer<typeof validationProblemDetailsSchema>
  | z.infer<ReturnType<typeof responseWithErrorsSchema>>
> {
  // .parse() will throw a ZodError if validation fails.
  const validatedPayload = payloadSchema.parse(payload);

  const response = await axios.patch(url, validatedPayload, config);
  // Determine the proper schema based on the status code.
  let parseSchema: z.ZodTypeAny;
  if (response.status === 200) {
    // Successful response
    parseSchema = responseWithErrorsSchema(responseSchema);
  } else if (response.status === 207) {
    // .NET–style response with errors
    parseSchema = responseWithErrorsSchema(responseSchema);
  } else if (response.status === 400) {
    // Could be standard ProblemDetails or ValidationProblemDetails
    parseSchema = z.union([
      problemDetailsSchema,
      validationProblemDetailsSchema,
    ]);
  } else if (response.status >= 400 && response.status < 600) {
    // For any other error responses, assume ProblemDetails
    parseSchema = problemDetailsSchema;
  } else {
    // Fallback to responseSchema if status code is unexpected.
    parseSchema = responseSchema;
  }

  // Validate the response data using the determined schema.
  const parsed = parseSchema.safeParse(response.data);

  if (!parsed.success) {
    // If none of the schemas match, throw an error.
    console.log(`status: ${response.status} had these errors: `, parsed.error);
    throw new Error("Response did not match any expected schema.");
  }
  return parsed.data;
}
