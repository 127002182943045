import { DateTime, Interval } from "luxon";
import { useState } from "react";
import { ErrorMessage } from "@hookform/error-message";
import { Controller, useFormContext } from "react-hook-form";
import DatePicker from "react-datepicker";
import styles from "./SimulatorReservationContainer.styles.";

const DateSelect: React.FC<DateSelectProps> = (props) => {
  const { control, setValue, formState, clearErrors, watch } = useFormContext();
  const { reservedDuration, reservedStartTime } = props;

  const [datePickerTime, setTime] = useState({
    startTime: new Date(reservedStartTime),
    endTime: DateTime.fromISO(reservedStartTime)
      .plus({ minutes: reservedDuration })
      .toJSDate(),
  });
  const { startTime, endTime } = datePickerTime;

  const handleStartTime = (value: Date) => {
    setTime({ startTime: value, endTime });
    setValue("startTime", value);
    handleSetRoundDuration();
    clearErrors("startTime");
  };
  const handleEndTime = (value: Date) => {
    setTime({ startTime, endTime: value });
    setValue("endTime", value);
    handleSetRoundDuration();
    clearErrors("endTime");
  };

  function handleSetRoundDuration() {
    setValue(
      "time",
      Interval.fromDateTimes(watch("startTime"), watch("endTime")).length(
        "minutes"
      ) || 0
    );
  }

  return (
    <div className={styles.dateContainer}>
      <div>
        <span className={styles.dateSpan}>Start</span>
        <Controller
          name="startTime"
          control={control}
          rules={{ required: "Start time is required" }}
          data-testid="selectStartTime"
          defaultValue={new Date(DateTime.local().toISO())}
          render={({ field }) => (
            <DatePicker
              {...field}
              onChange={(date: Date) => handleStartTime(date)}
              selected={datePickerTime.startTime}
              showTimeSelect
              timeIntervals={5}
              timeCaption="Time"
              dateFormat="h:mm aa"
              className={styles.datePicker}
              maxDate={new Date(DateTime.local().plus({ days: 1 }).toISO())}
              minDate={new Date(DateTime.local().toISO())}
              showPopperArrow={false}
            />
          )}
        />
        <ErrorMessage
          errors={formState.errors}
          name="startTime"
          render={({ message }) => (
            <div className="text-sm text-red-500">{message}</div>
          )}
        />
      </div>
      <div>
        <span className={styles.dateSpan}>End</span>
        <Controller
          name="endTime"
          control={control}
          rules={{ required: "End time is required" }}
          data-testid="selectEndTime"
          defaultValue={
            new Date(
              DateTime.local()
                .plus({ minutes: reservedDuration || 60 })
                .toISO()
            )
          }
          render={({ field }) => (
            <DatePicker
              {...field}
              onChange={(date: Date) => handleEndTime(date)}
              selected={datePickerTime.endTime}
              showTimeSelect
              timeIntervals={5}
              timeCaption="Time"
              dateFormat="h:mm aa"
              className={styles.datePicker}
              maxDate={new Date(DateTime.local().plus({ days: 1 }).toISO())}
              minDate={new Date(DateTime.local().toISO())}
              showPopperArrow={false}
            />
          )}
        />
        <ErrorMessage
          errors={formState.errors}
          name="endTime"
          render={({ message }) => (
            <div className="text-sm text-red-500">{message}</div>
          )}
        />
      </div>
    </div>
  );
};
export default DateSelect;

type DateSelectProps = {
  reservedDuration?: number;
  reservedStartTime: string;
};
