export const styles = {
  overlay:
    "bg-gray-600 bg-opacity-30 px-5 fixed h-full w-full flex items-center justify-center top-0 left-0 z-9998",
  trayContainer:
    "fixed bg-gray-50 dark:bg-zinc-800 brand-dark-gray  drop-shadow-xl top-0 right-0 w-full max-w-2xl h-screen z-9999 overflow-y-scroll text-sm border-l border-gray-400 dark:border-gray-800",
  rosterAndTeamContainer:
    "fixed bg-gray-50 dark:bg-zinc-800 brand-dark-gray  drop-shadow-xl top-0 right-0 w-full max-w-3xl h-screen z-9999 overflow-y-scroll text-sm border-l border-gray-400 dark:border-gray-800 scrollbar scrollbar-thumb-gray-400 scrollbar-track-gray-300",
  trayHeaderContainer:
    "h-full flex flex-col bg-gray-100  dark:bg-zinc-800  shadow-xl overflow-y-scroll border-t-4",
  headerContainer:
    "grid grid-cols-5 items-center w-full h-16 border-b border-gray-300 dark:border-gray-800 bg-white  dark:bg-zinc-900  shadow-sm px-4 md:px-8",
  headerCloseButton:
    "dark:text-gray-200 text-xs px-2 py-1 poppins text-gray-500",
  trayHeader: "text-xl font-bold leading-loose pb-4 inline dark:text-gray-200",
  button:
    "bg-brandGreen text-white hover:bg-brandYellow hover:text-black rounded-lg poppins text-xs px-4 py-2 disabled:cursor-not-allowed disabled:opacity-50",
  helpDocText: "mt-6 relative flex-1 px-8 text-sm",
  headerTitle: "col-span-3 text-center ",
  headerRightButton: "text-right align-baseline",
  innerContainer:
    "flex flex-col md:flex-row min-h-screen w-full px-8 bg-brandLightGrey dark:bg-zinc-900/80",
  motionContainer: "w-full sm:m-4 relative",
  filterContainer: "md:flex md:items-center md:justify-between px-0 pb-4",
  filterInnerContainerLeft: "pt-4 lg:pt-0 flex-1 min-w-0 text-xs",
  filterInnerContainerRight: "mt-4 flex lg:mt-0 lg:ml-4",
  createButton:
    "poppins bg-brandYellow hover:bg-brandGreen dark:bg-brandYellow brand-dark-gray text-xs hover:text-white hover:dark:text-black poppins px-4 py-2 border dark:border-none border-white rounded-xl",
  filterButton:
    "rounded-full px-2 py-0.5 text-black uppercase tracking-normal mr-1 shadow-sm bg-gray-300 poppins hover:bg-gray-400",
  leagueCardContainer:
    "grid gap-8 grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 h-auto",
  noLeaguesAvailable:
    "text-xs text-gray-400 p-8 dark:bg-zinc-900 dark:text-gray-200 text-center h-full",
  noLeaguesIcon:
    "block border border-gray-400 rounded p-1 mb-2 w-6 h-6 text-gray-400 dark:text-gray-200",
  leagueCard:
    "flex flex-col py-6 px-8 pb-8 border border-gray-100 dark:border-none rounded-xl text-xs brand-dark-gray dark:text-gray-200 bg-white dark:bg-zinc-800 ",
  leagueCardHeaderRow: "flex flex-row w-full pb-2 poppins",
  leagueCardDateRow:
    "flex flex-row w-full pt-5 brand-dark-gray dark:text-darkCream text-xs ",
  leagueCardDetailsRow: "flex flex-row w-full brand-dark-gray text-xs poppins",
  leagueCardRow:
    "flex flex-row w-full pt-4 mt-4 brand-dark-gray dark:text-darkCream",
  leagueCardDateCol: "flex flex-row w-1/2 text-gray-400 dark:text-gray-100",
  leagueCardCol: "flex flex-col w-1/2 text-gray-400",
  leagueCardColFullWidth: "flex flex-col w-full text-gray-400",
  leagueCardFooterRow: "flex flex-row w-full pt-2 text-xs",
  leagueTitle:
    "brand-text block text-sm font-semibold my-1 text-ellipsis overflow-hidden w-48",
  leagueEditButton:
    "flex w-8 h-8 text-gray-400 hover:bg-gray-100 hover:rounded-full dark:hover:bg-zinc-600 hover:text-green-600 hover:border-white dark:border-zinc-600 hover:shadow-inner disabled:cursor-not-allowed disabled:bg-gray-100",
  leagueDeleteButton:
    "flex w-8 h-8 text-gray-400 hover:bg-gray-200 dark:hover:bg-zinc-600 hover:rounded-full hover:text-red-600 hover:border-white disabled:cursor-not-allowed disabled:bg-gray-100 dark:disabled:bg-zinc-600",
  leagueEditDeleteContainer:
    "hidden items-center content-center w-9 h-8  bg-gray-100 dark:bg-zinc-900 rounded-full hover:rounded-full align-right text-right ",
  leagueButtonIcon: "",
  leagueViewRoundsButton:
    "bg-brandGreen hover:bg-darkCream rounded-2xl w-full p-2 text-white hover:text-brandGreen font-normal brand-text cursor-pointer hover:shadow-sm text-center ",
  leagueOtherButton:
    "text-brandGreen dark:text-white dark:hover:text-black bg-gray-200 dark:bg-zinc-500 rounded-2xl w-full p-2 font-normal brand-text hover:bg-brandGreyText dark:hover:bg-zinc-400 hover:text-white text-xs h-9",
  leagueCancelButton:
    "text-black bg-gray-200 rounded-lg px-4 py-2 ml-1 font-normal poppins hover:bg-brandGreyText hover:text-white tracking-tight text-xs shadow",
  formRowLabel:
    "text-sm font-normal text-gray-800 dark:text-gray-200 poppins pb-3 mt-5",
  inputClassName:
    "block w-full px-3 py-2 border border-gray-400 dark:border-gray-50 rounded-lg placeholder-gray-400 focus:outline-none focus:ring-blue-400 focus:border-blue-400 sm:text-sm dark:text-gray-50 bg-white dark:bg-zinc-800 valid:border-green-500 dark:valid:border-green-500 disabled:cursor-not-allowed disabled:bg-gray-100",
  dateContainer: "grid grid-cols-2 gap-2",
  dateSpan: "text-sm font-bold text-gray-600 dark:text-gray-200 pt-2 mb-3",
  datePicker:
    "dark:text-black w-full border border-gray-400 rounded-lg h-12 text-sm disabled:cursor-not-allowed disabled:bg-gray-100 valid:border-green-500",
  datePickerError:
    "dark:text-red w-64 border-2 border-red-200 rounded-lg h-12 text-sm disabled:cursor-not-allowed disabled:bg-gray-100",
  titleRow: "flex flex-row text-xs text-gray-400 poppins font-normal leading-5",
  titleDescription:
    "flex flex-row text-xs text-gray-900 dark:text-darkCream font-normal poppins mb-4",
  dateDescription:
    "flex flex-row text-xs text-gray-900 dark:text-darkCream font-normal poppins mb-4",
  breadcrumbLink:
    "poppins text-xs text-brandDarkGray hover:text-gray-600 dark:text-gray-200 dark:hover:text-gray-300",
  modalCloseButton:
    "bg-darkCream dark:bg-zinc-900 text-brandGreen dark:text-gray-200  hover:bg-brandGreen hover:text-darkCream rounded-md px-1 py-1 text-xs poppins shadow mr-2 tracking-wide",
  modalFooter: "flex px-8 justify-end text-right",
  modalToggleInactive:
    "w-1/2 md:w-auto text-center inline-flex justify-center transition-colors duration-300 ease-in focus:outline-none hover:dark-gray rounded-lg px-4 py-2.5",
  modalToggleActive:
    "w-1/2 md:w-auto bg-brandGreen dark:bg-gray-500 text-white rounded inline-flex justify-center transition-colors duration-300 ease-in focus:outline-none hover:dark-gray px-4 py-2.5",
  modalToggleContainer:
    "flex w-full md:w-auto bg-gray-100 dark:bg-black poppins p-1 text-xs text-gray-500 leading-none shadow-sm rounded-xl inline-flex ",
  modalToggleContainerOuter:
    "flex w-full md:w-1/2 poppins text-black text-right mt-4 md:mt-0 items-center justify-start md:justify-end",
  modalHeading:
    "flex w-full md:w-1/2 items-center text-brandDarkGray dark:text-gray-200 text-left",
  modalHeader: "flex flex-col md:flex-row justify-between content-center p-4",
  modalSearchButton:
    "bg-gray-100 dark:bg-brandGreen text-black dark:text-white rounded-full px-2 py-2 mr-3 inline-flex text-xs poppins shadow hover:shadow-sm",
  modalBody: "h-full relative",
  modalLoading: "flex items-center h-3/4 w-full",
  modalNoResults:
    "block items-center text-center w-full text-gray-500 text-sm mt-6",
  modalResultsHeaderRow:
    "grid grid-cols-12 w-full text-xs gap-3 py-4 gap-y-4 mb-4 px-12 poppins text-gray-400 dark:text-gray-100 dark:border-gray-100",
  modalResultsRow:
    "grid grid-cols-12 w-full text-xs gap-3 py-4 border-b border-gray-100 dark:text-gray-200 gap-y-2 font-semibold items-center",
  modalTeamResultsRow:
    "grid grid-cols-12 w-full text-xs gap-3 py-2 border-b border-gray-50 dark:border-gray-600 dark:text-gray-200 gap-y-2 font-bold items-center",
  modalNoScorecardPlayerRow:
    "grid grid-cols-12 w-full text-xs gap-3 py-2 gap-y-2 font-normal items-center text-gray-300 dark:text-gray-400",
  showScorecardButton:
    "text-black dark:text-gray-200 rounded-full px-1 py-1 hover:shadow-sm hover:bg-brandGreen hover:text-darkCream text-xs font-semibold inline justify-center hover:cursor-pointer",
  finalizeRoundButton:
    "text-black dark:text-gray-200 rounded-full px-1 py-1  hover:text-brandGreen text-xs font-semibold justify-center hover:cursor-pointer",
  leaderboardModalContainer:
    "bg-white  dark:bg-zinc-800  p-8 rounded-lg shadow-lg absolute top-0 left-0 w-full h-full z-10",
  leagueFormFootnotes: "text-left text-xs text-zinc-800 dark:text-gray-50 pt-1",
};
