import axios, { AxiosError } from "axios";
import FormData from "form-data";
import toast from "react-hot-toast";
import {
  Clubhouse_AdKitImagesUploadResponseDto,
  Clubhouse_AdKitSamplesDto,
  Clubhouse_AdKitImageDto,
  ImageFile,
  UpdateAdKitImageDto,
  ProblemDetailsException,
  SluggedId,
  Clubhouse_UpdateAdKitImageDto,
  ProblemDetails,
  ValidationProblemDetails,
} from "Models";
import {
  pendoTrackBasicEvent,
  ClubhouseEvents,
} from "utils/pendo/pendoHelpers";
import {
  ClubhouseAdKitDto,
  ClubhouseAdKitLocationDto,
  ClubhouseAdKitLocationInstanceDto,
  ClubhouseAdKitDtoArraySchema,
  ClubhouseAdKitDtoSchema,
} from "entities/responses/adkit";
import {
  CreateAdKitDto,
  UpdateAdKitDto,
  CreateAdKitLocationDto,
  CreateAdKitLocationInstanceDto,
  UpdateAdKitDtoSchema,
  CreateAdKitDtoSchema,
} from "entities/requests/adkit";

import {
  clubhouseApiHeadersMultiPart,
  clubhouseApiHeadersV2,
  fetchWithValidation,
  deleteWithValidation,
  postWithValidation,
  patchWithValidation,
} from "utils/api/apiHelper";

export async function fetchAdKitMetaData() {
  const url = `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/ad-kit/metadata`;
  const { data } = await axios.get<Clubhouse_AdKitSamplesDto>(
    url,
    clubhouseApiHeadersV2()
  );

  pendoTrackBasicEvent(ClubhouseEvents.ADKIT_VIEW);
  return data;
}

export async function uploadAdKitImages(
  currentFacilityId: string,
  uploadFileList: ImageFile[]
) {
  if (!uploadFileList || uploadFileList.length === 0) return;

  const url = `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/ad-kit/facility/${currentFacilityId}/images`;
  const formData = new FormData();

  uploadFileList.forEach((file, index) => {
    formData.append(`Files[${index}].file`, file.file);
    formData.append(`Files[${index}].fileName`, file.fileName);
    formData.append(`Files[${index}].imageName`, file.name);
    formData.append(`Files[${index}].contentType`, file.contentType);
    formData.append(`Files[${index}].overrideWarnings`, "true"); // this should come from the UI
  });

  try {
    const { data } = await axios.post<Clubhouse_AdKitImagesUploadResponseDto>(
      url,
      formData,
      clubhouseApiHeadersMultiPart()
    );

    pendoTrackBasicEvent(ClubhouseEvents.ADKIT_UPLOAD_IMAGES);
    toast.success("Images uploaded successfully");

    return data;
  } catch (error: any) {
    if (axios.isAxiosError(error) && error.response) {
      if (error.response.status === 400) {
        let errors = (error?.response
          ?.data as Clubhouse_AdKitImagesUploadResponseDto).errors;

        toast.error(
          errors ? errors.map((err) => err.message).join(", ") : "Bad request",
          { duration: 5000 }
        );
      } else {
        handleErrorMessages(error.response.status, error);
      }
    } else {
      toast.error(`An unexpected error occurred: ${error}`);
    }
    return [];
  }
}

export async function updateAdKitImage(
  currentFacilityId: string,
  imageId: SluggedId,
  updatedFile: UpdateAdKitImageDto
) {
  if (!updatedFile) return;

  const url = `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/ad-kit/facility/${currentFacilityId}/image/${imageId}`;
  const formData = new FormData();

  //formData.append(`File.file`, updatedFile.);
  formData.append(`File.imageName`, updatedFile.name);

  try {
    const { data } = await axios.put<Clubhouse_AdKitImageDto>(
      url,
      formData,
      clubhouseApiHeadersMultiPart()
    );

    pendoTrackBasicEvent(ClubhouseEvents.ADKIT_UPLOAD_IMAGES);
    toast.success("Image updated successfully");
    return data;
  } catch (error) {
    let response = error as ProblemDetailsException;

    toast.error("Error fetching AdKit images: " + response.extensions?.message);
    throw error;
  }
}

export async function getAdKitImages(
  currentFacilityId: string,
  getDeletedImages?: boolean
) {
  const url = `${process.env.REACT_APP_HTTPS_PROTOCOL}${
    process.env.REACT_APP_BASE_URL
  }/api/ad-kit/facility/${currentFacilityId}/images${
    getDeletedImages ? "?includeDeletedImages=true" : ""
  }`;

  try {
    const { data } = await axios.get<Clubhouse_AdKitImageDto[]>(
      url,
      clubhouseApiHeadersV2()
    );
    return data;
  } catch (error) {
    toast.error("Error fetching AdKit images: " + error);
    throw error;
  }
}

export async function putAdKitImage(
  currentFacilityId: string,
  imageId: SluggedId
) {
  const url = `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/ad-kit/facility/${currentFacilityId}/image/${imageId}/restore`;

  try {
    const response = await axios.put(url, {}, clubhouseApiHeadersV2());

    toast.success("Image restored successfully");
    return response;
  } catch (error: any) {
    if (axios.isAxiosError(error) && error.response) {
      handleErrorMessages(error.response.status, error);
    } else {
      toast.error(`An unexpected error occurred: ${error}`);
    }
    return [];
  }
}

export async function patchAdKitImage(
  currentFacilityId: string,
  imageId: SluggedId,
  payload: Clubhouse_UpdateAdKitImageDto
) {
  const url = `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/ad-kit/facility/${currentFacilityId}/image/${imageId}`;

  try {
    const { data } = await axios.patch(url, payload, clubhouseApiHeadersV2());

    toast.success("Image updated successfully");
    return data;
  } catch (error: any) {
    if (axios.isAxiosError(error) && error.response) {
      handleErrorMessages(error.response.status, error);
    } else {
      toast.error(`An unexpected error occurred: ${error}`);
    }
    return [];
  }
}

export async function deleteAdKitImage(
  currentFacilityId: string,
  imageId: SluggedId
) {
  const url = `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/ad-kit/facility/${currentFacilityId}/image/${imageId}`;

  try {
    const { data } = await axios.delete<Clubhouse_AdKitImageDto>(
      url,
      clubhouseApiHeadersV2()
    );

    toast.success("Image deleted successfully");
    return data;
  } catch (error: any) {
    if (axios.isAxiosError(error) && error.response) {
      handleErrorMessages(error.response.status, error);
    } else {
      toast.error(`An unexpected error occurred: ${error}`);
    }
    return [];
  }
}

export async function getAllAdKits(
  currentFacilityId: string
): Promise<ClubhouseAdKitDto[]> {
  const url = `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/ad-kit/facility/${currentFacilityId}/adkits`;
  try {
    const data = await fetchWithValidation<ClubhouseAdKitDto[]>(
      url,
      ClubhouseAdKitDtoArraySchema,
      clubhouseApiHeadersV2()
    );

    const result = ClubhouseAdKitDtoArraySchema.safeParse(data);

    if (result.success) {
      return result.data;
    } else {
      throw result;
    }
  } catch (error: any) {
    if (axios.isAxiosError(error) && error.response) {
      handleErrorMessages(error.response.status, error);
    } else {
      toast.error(`An unexpected error occurred: ${error}`);
    }
    return [];
  }
}

export async function getSingleAdKit(
  currentFacilityId: string,
  adKitId: string
) {
  const url = `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/ad-kit/facility/${currentFacilityId}/adkit/${adKitId}`;
  try {
    const data = await fetchWithValidation<ClubhouseAdKitDto>(
      url,
      ClubhouseAdKitDtoSchema,
      clubhouseApiHeadersV2()
    );
    const result = ClubhouseAdKitDtoSchema.safeParse(data);
    if (result.success) {
      return result.data;
    } else {
      toast.error("Error fetching AdKit (ProblemData): " + result.error);
      throw result.error;
    }
  } catch (error) {
    toast.error("Error fetching AdKit: " + error);
    throw error;
  }
}

export async function createAdKit(
  currentFacilityId: string,
  overrideWarnings: boolean,
  payload: ClubhouseAdKitDto | CreateAdKitDto // this probably isn't the best way to do this, I think in the CreateAdkit.tsx file, we should be using use the CreateAdKitDto
) {
  const url = `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/ad-kit/facility/${currentFacilityId}/adkits`;

  try {
    const createAdKitPayload = ensureCreateAdKitDto(payload, overrideWarnings); // having to do this feels somewhat wrong and hacky
    //const { data } = await axios.post(url, payload, clubhouseApiHeadersV2());
    const data = await postWithValidation<CreateAdKitDto, ClubhouseAdKitDto>(
      url,
      CreateAdKitDtoSchema,
      createAdKitPayload,
      ClubhouseAdKitDtoSchema,
      clubhouseApiHeadersV2()
    );
    return data;
  } catch (error) {
    toast.error("Error creating AdKit: " + error);
    throw error;
  }
}

function convertClubhouseAdKitLocationInstanceToCreateAdKitLocationInstance(
  instance: ClubhouseAdKitLocationInstanceDto,
  overrideWarnings: boolean
): CreateAdKitLocationInstanceDto {
  return {
    imageId: instance.id,
    overrideWarnings: overrideWarnings,
    holeNumber: instance.holeNumber ?? 0,
    optionalImageFilePathOverride: null,
    orderLocation: instance.instanceId ?? -1,
    widthAdjustment: instance.widthAdjustment ?? -1,
    heightAdjustment: instance.heightAdjustment ?? -1,
  };
}

function convertClubhouseAdKitLocationToCreateAdKitLocation(
  location: ClubhouseAdKitLocationDto,
  overrideWarnings: boolean
): CreateAdKitLocationDto {
  return {
    adKitMetaDataId: location.adKitMetaDataId,
    enabledHoles: location.enabledHoles,
    overrideWarnings: overrideWarnings,
    adInstances: location.adInstances?.map((instance) =>
      convertClubhouseAdKitLocationInstanceToCreateAdKitLocationInstance(
        instance,
        overrideWarnings
      )
    ),
  };
}

function convertClubhouseAdKitToCreateAdKit(
  adKit: ClubhouseAdKitDto,
  overrideWarnings: boolean
): CreateAdKitDto {
  return {
    name: adKit.name,
    description: adKit.description ?? null,
    fileName: adKit.fileName,
    transitionDelay: adKit.transition.display,
    transitionFadeIn: adKit.transition.fadeIn,
    transitionFadeOut: adKit.transition.fadeOut,
    adLocations: adKit.adLocations
      ? Object.values(adKit.adLocations).map((instance) =>
          convertClubhouseAdKitLocationToCreateAdKitLocation(
            instance,
            overrideWarnings
          )
        )
      : undefined,
  };
}

function isClubhouseAdKitDto(
  payload: ClubhouseAdKitDto | CreateAdKitDto
): payload is ClubhouseAdKitDto {
  return (
    // Check for properties specific to ClubhouseAdKitDto that are not in CreateAdKitDto
    "transition" in payload &&
    typeof payload.transition === "object" &&
    "id" in payload &&
    "lastModified" in payload &&
    "created" in payload
  );
}

function ensureCreateAdKitDto(
  payload: ClubhouseAdKitDto | CreateAdKitDto,
  overrideWarnings: boolean
): CreateAdKitDto {
  return isClubhouseAdKitDto(payload)
    ? convertClubhouseAdKitToCreateAdKit(payload, overrideWarnings)
    : payload;
}

function handleErrorMessages(
  responseCode: number,
  error: AxiosError<ProblemDetails | ValidationProblemDetails>
) {
  const detail = error?.response?.data?.detail;
  const errors = (error?.response?.data as ValidationProblemDetails)?.errors;

  if (responseCode === 404 || responseCode === 409) {
    toast.error(detail || "Resource not found");
  } else if (responseCode === 400) {
    toast.error(errors ? JSON.stringify(errors) : "Bad request");
  } else {
    toast.error(`An unexpected error occurred: ${error}`);
  }
}

export async function updateAdKit(
  currentFacilityId: string,
  payload: UpdateAdKitDto
) {
  const url = `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/ad-kit/facility/${currentFacilityId}/adkits`;

  try {
    const data = await patchWithValidation<UpdateAdKitDto, ClubhouseAdKitDto>(
      url,
      UpdateAdKitDtoSchema,
      payload,
      ClubhouseAdKitDtoSchema,
      clubhouseApiHeadersV2()
    );
    return data;
  } catch (error) {
    toast.error("Error updating AdKit: " + error);
  }
}

export async function deleteAdKit(currentFacilityId: string, adKitId: string) {
  const url = `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/ad-kit/facility/${currentFacilityId}/adkit/${adKitId}`;

  try {
    const data = await deleteWithValidation(url, clubhouseApiHeadersV2());

    return data;
  } catch (error) {
    toast.error("Error deleting AdKit: " + error);
    throw error;
  }
}
