export const styles = {
  simulatorSortCardContainer:
    "flex flex-row visible text-opacity-100 bg-white dark:bg-zinc-600 border border-gray-200 dark:border-zinc-800 text-black text-sm rounded-lg p-4 poppins active:bg-brandYellow z-9999 shadow-sm",
  sortGrab: "flex flex-0 w-8 hover:cursor-grab justify-items-end m-0 p-0 mt-3",
  button:
    "text-white bg-brandGreen rounded text-xs px-2 py-1 poppins disabled:opacity-50 disabled:cursor-not-allowed",
  spinnerContainer:
    "w-full h-full min-h-64 bg-white bg-opacity-50 z-50 flex items-center justify-center",
  sortedList: "select-none grid auto-cols-auto gap-2 z-75",
  editLinks:
    "text-xs mulish pt-0.5 ml-2 text-gray-300 hover:text-black hover:cursor-pointer",
  facilityName: "text-sm border-b border-gray-200 dark:border-white pb-2 mb-2",
  facilitySettingsRow: "flex flex-col",
  facilitySettingsColumnOne:
    "flex w-1/2 h-12 text-sm poppins text-black dark:text-gray-200 pt-4 pb-2",
  facilitySettingsColumnTwo:
    "flex w-full h-10 text-sm poppins text-black items-center",
  facilitySettingsInputNumber:
    "border-2 border-gray-300 h-8 m-0 px-2 rounded-md text-sm w-16",
  span: "flex text-xs text-gray-400 dark:text-gray-50 ml-2 tracking-wide",
};
