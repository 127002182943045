const CreateAdKitStepTwo: React.FC = () => {
  return (
    <div>
      <div className="space-y-2">
        <p className="text-md font-bold">Choose Locations</p>
        <p className="text-muted-foreground text-zinc-400 text-xs pb-2">
          Select which ad locations you want to place these images
        </p>
      </div>
      <div>
        <div className="grid gap-4 sm:grid-cols-2 2xl:grid-cols-3"></div>
      </div>
    </div>
  );
};
export default CreateAdKitStepTwo;
