import { useState } from "react";
import { RootState } from "StoreTypes";
import { useSelector } from "react-redux";
import { StepIndicator } from "./step-indicator";
import { FormProvider, useForm } from "react-hook-form";
import { ClubhouseAdKitDto } from "entities/responses/adkit";
import { toSluggedId, DEFAULT_DATETIME } from "entities/common";
import CreateAdKitStepOne from "./CreateAdKitStepOne";
import CreateAdKitStepTwo from "./CreateAdKitStepTwo";
import CreateAdKitStepThree from "./CreateAdKitStepThree";

const CreateAdkit: React.FC<AdKitProps> = (props) => {
  const selectedAdKit = useSelector(
    (state: RootState) => state.adkit?.selectedAdKit
  );

  const [step, setStep] = useState<number>(1);

  const methods = useForm({
    defaultValues: selectedAdKit || {
      id: toSluggedId(""),
      name: "",
      description: "",
      facilityId: "",
      transition: { display: 0, fadeIn: 0, fadeOut: 0 },
      fileName: "",
      deleted: false,
      created: DEFAULT_DATETIME,
      lastModified: DEFAULT_DATETIME,
    },
  });

  const handleStepChange = (direction: "next" | "previous") => {
    setStep((prevStep) => (direction === "next" ? prevStep + 1 : prevStep - 1));
  };

  const handleOnSubmit = async (data: any) => {
    console.log("Submit", data);
  };

  return (
    <div>
      <div>
        <StepIndicator
          currentStep={step}
          steps={["Create AdKit", "Choose Locations", "Select Sims", "Review"]}
        />
      </div>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(handleOnSubmit)}
          data-testid="createAdKitForm"
          className="mt-8"
        >
          {step === 1 && <CreateAdKitStepOne />}
          {step === 2 && <CreateAdKitStepTwo />}
          {step === 3 && <CreateAdKitStepThree />}
        </form>
      </FormProvider>

      <div className="w-full mb-8">
        <div className="flex justify-between mt-4">
          {step > 1 && (
            <button
              onClick={() => handleStepChange("previous")}
              className="bg-gray-200 text-black px-4 py-2 rounded-lg text-sm hover:text-white hover:bg-brandGreen"
            >
              Previous
            </button>
          )}
          <button
            onClick={() =>
              step === 4 ? console.log("Submit") : handleStepChange("next")
            }
            className={`px-4 py-2 rounded-lg text-sm ${
              step === 4
                ? "bg-brandYellow text-black hover:text-white hover:bg-brandGreen"
                : "bg-brandYellow text-black hover:text-white hover:bg-brandGreen"
            }`}
          >
            {step === 4 ? "Save AdKit" : "Next"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateAdkit;

type AdKitProps = {
  adkit?: ClubhouseAdKitDto;
  callback?: any;
};
