import { applyMiddleware, createStore, combineReducers } from "redux";
import { entitiesReducer, queriesReducer, queryMiddleware } from "redux-query";
import superagentInterface from "redux-query-interface-superagent";
import { composeWithDevTools } from "redux-devtools-extension";
import { RootState } from "StoreTypes";
import { navMenuReducer } from "ui-modules/nav/navSlice";
import { modalReducer } from "ui-modules/modals/modalSlice";
import { userReducer } from "modules/user/userSlice";
import { facilityReducer } from "ui-modules/facility-control/facilityControlSlice";
import { signalRMiddleware } from "utils/websockets/websocketMiddleware";
import { simulatorReservationReducer } from "ui-modules/simulator-reservation/simulatorReservationSlice";
import { eventReducer } from "ui-modules/event/eventSlice";
import { leagueReducer } from "modules/leagues/leagueSlice";
import { queueReducer } from "ui-modules/queue/queueSlice";
import { adKitReducer } from "ui-modules/adkit/adKitSlice";

export const getQueries = (state: RootState) => state.queries;
export const getEntities = (state: RootState) => state.entities;

const rootReducer = combineReducers({
  entities: entitiesReducer,
  queries: queriesReducer,
  nav: navMenuReducer,
  modals: modalReducer,
  user: userReducer,
  facility: facilityReducer,
  simulatorReservation: simulatorReservationReducer,
  queue: queueReducer,
  event: eventReducer,
  league: leagueReducer,
  adkit: adKitReducer,
});

const middlewares = [
  queryMiddleware(superagentInterface, getQueries, getEntities),
  signalRMiddleware,
];

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middlewares))
);

export default store;
