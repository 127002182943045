import { FormRow } from "components/ui/form";
import { Controller, useFormContext } from "react-hook-form";
import Select from "react-select";
import { roundConfigEnumsAsReactSelectOptions } from "utils/enums/enumHelper";
import { ErrorMessage } from "@hookform/error-message";
import { customStyles } from "utils/ui/uiHelpers";

const RangeSpecificForm: React.FC = () => {
  const { control, formState } = useFormContext();
  return (
    <div>
      <FormRow
        header="What range venue would you like?"
        fontSize="text-sm mt-2 text-zinc-800 poppins dark:text-gray-200 pb-2"
      >
        <Controller
          name="range"
          rules={{ required: "You must select a venue" }}
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              closeMenuOnSelect={true}
              isMulti={false}
              options={roundConfigEnumsAsReactSelectOptions.ranges}
              value={roundConfigEnumsAsReactSelectOptions.ranges.find(
                (c) => c.value === field.value
              )}
              onChange={(val: { label: string; value: string }) =>
                field.onChange(val.value)
              }
              styles={customStyles}
              menuPlacement="auto"
            />
          )}
        />
        <ErrorMessage
          errors={formState.errors}
          name="range"
          render={({ message }) => (
            <div className="text-sm text-red-500">{message}</div>
          )}
        />
      </FormRow>
    </div>
  );
};

export default RangeSpecificForm;
