import { FormRow } from "components/ui/form";
import { Controller, useFormContext } from "react-hook-form";

const CreateAdKitStepOne: React.FC = () => {
  const { control, watch } = useFormContext();
  console.log(watch("name"));
  console.log(watch("description"));
  return (
    <div>
      <div className="space-y-2 justify-between justify-items-center flex mb-4 text-zinc-900 dark:text-gray-50">
        <div className="flex flex-col">
          <p className="text-md font-bold">Welcome to AdKit</p>

          <p className="text-muted-foreground text-zinc-400 text-xs pb-2">
            aG Simulators allow you to create and display dynamic ads throughout
            the game experience.
          </p>
          <p className="text-muted-foreground text-zinc-400 text-xs pb-2">
            An AdKit allows you to upload/manage ad images, assign to various
            locations inside the game, and then determine which sims the ad
            experience displays in.
          </p>
          <p className="text-muted-foreground text-zinc-400 text-xs pb-2">
            To create an AdKit first start with a name and description. Next you
            will be able to upload and manage images. AdKits can be edited at
            any time.
          </p>
          <FormRow
            header="AdKit Name"
            fontSize="text-sm font-normal text-gray-800 dark:text-gray-200 poppins pb-3 mt-5"
          >
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <input
                  {...field}
                  type="text"
                  autoComplete="Name"
                  id="adKitName"
                  className="block w-full px-3 py-2 border border-gray-400 dark:border-gray-50 rounded-lg placeholder-gray-400 focus:outline-none focus:ring-blue-400 focus:border-blue-400 sm:text-sm dark:text-gray-50 bg-white dark:bg-zinc-800 valid:border-green-500 dark:valid:border-green-500 disabled:cursor-not-allowed disabled:bg-gray-100"
                  tabIndex={1}
                  data-testid="name"
                />
              )}
            />
          </FormRow>
          <FormRow
            header="AdKit Description"
            fontSize="text-sm font-normal text-gray-800 dark:text-gray-200 poppins pb-3 mt-5"
          >
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <textarea
                  {...field}
                  id="description"
                  rows={3}
                  className="shadow-sm focus:ring-light-blue-500 focus:border-light-blue-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md valid:border-green-500 dark:valid:border-green-500"
                  tabIndex={2}
                />
              )}
            />
          </FormRow>
        </div>
      </div>
    </div>
  );
};

export default CreateAdKitStepOne;
