import { RootState } from "StoreTypes";
import { useSelector } from "react-redux";
import { useAppDispatch } from "utils/hooks";
import { ModalType } from "utils/modals/modalHelpers";
import { modalAction } from "ui-modules/modals/modalSlice";
import { setSelectedAdKit } from "ui-modules/adkit/adKitSlice";
import { getCurrentFacility } from "modules/facility/facilitySelectors";
import Row from "components/ui/layout/Row";
import Modal from "components/shared/Modal";
import CreateAdkit from "./CreateAdkit";

const CreateNewAdkitModal: React.FC<CreateNewAdkitModalProps> = (props) => {
  const { callback } = props;

  const dispatch = useAppDispatch();

  const currentFacility = useSelector((state: RootState) =>
    getCurrentFacility(state)
  );

  const handleCloseModal = async () => {
    dispatch(
      setSelectedAdKit({
        selectedAdKit: {
          id: "",
          name: "",
          created: "",
        },
      })
    );
    dispatch(
      modalAction({
        isOpen: false,
        modalName: ModalType.AdKitModal,
      })
    );
    callback && callback();
  };

  return (
    <Modal
      modalName={props.modalName || ""}
      closeModalCallback={handleCloseModal}
      hideHeader={false}
      title={`Create a new AdKit for ${currentFacility?.name}`}
      hideFooter={true}
      modalSize="w-full h-screen md:w-2/3 2xl:w-1/2"
      body={
        <Row cssClasses="px-8 pb-4 h-full">
          <CreateAdkit />
        </Row>
      }
    />
  );
};

type CreateNewAdkitModalProps = {
  title?: string;
  modalName?: string;
  callback?: any;
};

CreateNewAdkitModal.defaultProps = {
  title: "Sim Management",
};

export default CreateNewAdkitModal;
