import { RootState } from "StoreTypes";
import { useSelector } from "react-redux";
import { useAppDispatch } from "utils/hooks";
import { ModalType } from "utils/modals/modalHelpers";
import { modalAction } from "ui-modules/modals/modalSlice";
import { getCurrentFacility } from "modules/facility/facilitySelectors";
import Row from "components/ui/layout/Row";
import Modal from "components/shared/Modal";
import AdKitImageManager from "./AdKitImageManager";

const AdKitImageManagerModal: React.FC<CreateNewAdkitModalProps> = (props) => {
  const dispatch = useAppDispatch();

  const currentFacility = useSelector((state: RootState) =>
    getCurrentFacility(state)
  );

  const handleCloseModal = async () => {
    dispatch(
      modalAction({
        isOpen: false,
        modalName: ModalType.AdKitImageManager,
      })
    );
  };

  return (
    <Modal
      modalName={props.modalName || ""}
      closeModalCallback={handleCloseModal}
      hideHeader={false}
      title={`Manage AdKit Images for ${currentFacility?.name}`}
      hideFooter={true}
      modalSize="w-full h-screen md:w-2/3 2xl:w-1/2"
      body={
        <Row cssClasses="px-8 pb-4 h-full">
          <AdKitImageManager />
        </Row>
      }
    />
  );
};

type CreateNewAdkitModalProps = {
  title?: string;
  modalName?: string;
  callback?: any;
};

AdKitImageManagerModal.defaultProps = {
  title: "Manage AdKit Images",
};

export default AdKitImageManagerModal;
