import { getJWTFromLocalStorage } from "utils/tokens/tokenHelpers";
import ReactGA from "react-ga4";

const currentFacilityId = getJWTFromLocalStorage();

export function pendoTrackBasicEvent(eventName: string, simulatorId?: string) {
  if (window.pendo) {
    window.pendo.track(eventName, {
      simulatorId: simulatorId ? simulatorId : "",
      facilityId: currentFacilityId,
    });
    ReactGA.event({
      category: "ClubhouseEvents",
      action: eventName,
    });
  }
}

export function pendoTrackEventWithProperties(
  eventName: string,
  props: object
) {
  if (window.pendo) {
    window.pendo.track(eventName, props);
    ReactGA.event({
      category: "ClubhouseEvents",
      action: eventName,
    });
  }
}

export enum ClubhouseEvents {
  CREATE_RESERVATION = "Create Simulator Reservation",
  DELETE_RESERVATION = "Delete Simulator Reservation",
  EDIT_RESERVATION = "Edit Simulator Reservation",
  START_ROUND = "Start Ad Hoc Round",
  END_ROUND = "End Round",
  SEND_TO_SIM = "Send Simulator Reservation",
  CLEAR_TIMER = "Clear Timer",
  PAUSE_TIMER = "Pause Timer",
  UNPAUSE_TIMER = "Unpause Timer",
  START_TIMER = "Start Timer",
  ADD_TIME = "Add Time",
  CREATE_EVENT = "Create Event",
  DELETE_EVENT = "Delete Event",
  EDIT_EVENT = "Edit Event",
  CREATE_TOUR = "Create Tour",
  CREATE_LEAGUE = "Create League",
  UPDATE_LEAGUE = "Update League",
  DELETE_LEAGUE = "Delete League",
  UPDATE_ROUND = "Update Round",
  CREATE_ROUND = "Create Round",
  DELETE_ROUND = "Delete Round",
  CREATE_ROUND_CONFIG = "Create Round Config",
  SHOW_LIST_VIEW = "Show List View",
  SHOW_GRID_VIEW = "Show Grid View",
  LEAGUE_VIEW_LEADERBOARD = "View League Leaderboard",
  LEAGUE_VIEW_ROSTER = "View League Roster",
  LEAGUE_VIEW_ROUNDS = "View League Rounds",
  LEAGUE_EDIT = "Edit League",
  LEAGUE_DELETE = "Delete League",
  LEAGUE_CREATE = "Create League",
  LEAGUE_ROUND_VIEW_GROUPS = "View League Round Groups",
  LEAGUE_ROUND_VIEW_LEADERBOARD = "View League Round Leaderboard",
  LEAGUE_ROUND_EDIT = "Edit League Round",
  LEAGUE_ROUND_DELETE = "Delete League Round",
  LEAGUE_ROUND_CREATE = "Create League Round",
  LEAGUE_ROUND_CREATE_GROUP = "Create League Round Group",
  LEAGUE_ROUND_SEND_GROUPS_TO_SIM = "Send Groups to Simulator",
  LEAGUE_ROUND_ASSIGN_GROUP_TO_SIM = "Assign Group to Simulator",
  LEAGUE_CREATE_TEAM = "Create League Team",
  LEAGUE_EDIT_TEAM = "Edit League Team",
  LEAGUE_DELETE_TEAM = "Delete League Team",
  LEAGUE_CREATE_MATCH = "Create League Match",
  LOCKER_ROOM_VIEW = "View Locker Room",
  LOCKER_ROOM_EXPORT = "Export Locker Room",
  LIVE_SCREENSHOTS = "Live Screenshots",
  LATEST_SCREENSHOT = "Latest Screenshot",
  UPDATE_FACILITY = "Update Facility",
  ADKIT_VIEW = "View AdKit",
  ADKIT_UPLOAD_IMAGES = "Upload AdKit Images",
  ADKIT_GET_IMAGES = "Get AdKit Images",
}
