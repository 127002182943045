import { useState } from "react";
import { styles } from "./Adkit.styles";
import { useGetCurrentLocalFacilityId } from "modules/facility/facilityHooks";
import {
  Clubhouse_AdKitImageDto,
  Clubhouse_UpdateAdKitImageDto,
  SluggedId,
} from "Models";
import {
  patchAdKitImage,
  deleteAdKitImage,
  putAdKitImage,
} from "./utils/adKitHelpers";
import toast from "react-hot-toast";

interface AdKitImageProps {
  file: Clubhouse_AdKitImageDto;
  index: number;
  showDeletedImages: boolean;
  callback?: any;
}

const AdKitImage: React.FC<AdKitImageProps> = ({
  file,
  index,
  showDeletedImages,
  callback,
}) => {
  const currentFacilityId = useGetCurrentLocalFacilityId();

  const [updateImage, setUpdateImage] = useState<Clubhouse_UpdateAdKitImageDto>(
    {}
  );

  const handleDeleteAdKitImage = async (imageFileId: SluggedId) => {
    if (window.confirm(`Are you sure you want to delete this image?`)) {
      await deleteAdKitImage(currentFacilityId, imageFileId);
      await toast.success("Image deleted successfully");
      callback && (await callback());
    }
  };

  const handleRestoreDeletedImage = async (image: Clubhouse_AdKitImageDto) => {
    await putAdKitImage(currentFacilityId, image.id);

    callback && (await callback());
  };

  const handleUpdateImage = async (imageId: SluggedId) => {
    await patchAdKitImage(currentFacilityId, imageId, updateImage);
    callback && (await callback());
  };

  return (
    <div
      key={index}
      className="relative group p-2 hover:bg-gray-50 border-2 border-gray-100 rounded-lg hover:border-gray-500"
    >
      <div className="relative aspect-square w-full overflow-hidden rounded-lg">
        <>
          {file.deleted ? (
            <img
              src="https://placehold.co/200x600/png"
              alt={`Restore ${file.name}`}
            />
          ) : (
            <img
              src={file.imageUrl}
              alt={`Preview ${file.name}`}
              className="object-cover w-full h-full hover:cursor-pointer"
            />
          )}
          {!file.deleted && (
            <button
              onClick={() => {
                handleDeleteAdKitImage(file.id);
              }}
              className="absolute rounded-md top-2 right-2 opacity-100 bg-red-800 transition-opacity"
            >
              <div className="h-6 w-6 text-white font-bold">&times;</div>
            </button>
          )}
        </>
      </div>
      <div className="relative">
        <span className="text-xs">File name</span>
        <input
          type="text"
          defaultValue={file.fileName}
          onBlur={(e) =>
            setUpdateImage({ ...updateImage, fileName: e.target.value })
          }
          className="mt-1 text-sm bg-white border border-gray-400 p-1 rounded-md w-full disabled:opacity-50 disabled:text-gray-500"
          disabled={file.deleted}
        />
        <span className="text-xs">Friendly name</span>
        <input
          type="text"
          defaultValue={file.name}
          onBlur={(e) =>
            setUpdateImage({ ...updateImage, name: e.target.value })
          }
          className="mt-1 text-sm bg-white border border-gray-400 p-1 rounded-md w-full disabled:opacity-50"
          disabled={file.deleted}
        />
        {file.deleted ? (
          <button
            onClick={() => handleRestoreDeletedImage(file)}
            className={`${styles.button} mt-2 w-full`}
          >
            Restore Image
          </button>
        ) : (
          <button
            onClick={() => handleUpdateImage(file.id)}
            className={`${styles.button} mt-2 w-full`}
          >
            Update Image
          </button>
        )}
      </div>
    </div>
  );
};

export default AdKitImage;
