import { DateTime } from "luxon";
import { RootState } from "StoreTypes";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { SimulatorScreenShotsDto } from "Models";
import { clubhouseApiHeadersV1 } from "utils/api/apiHelper";
import { getSimulatorBySimId } from "modules/simulator/simulatorSelectors";
import {
  ClubhouseEvents,
  pendoTrackEventWithProperties,
} from "utils/pendo/pendoHelpers";
import axios from "axios";
import Modal from "components/shared/Modal";

const LatestScreenshotModal: React.FC<LatestScreenshotModalProps> = (props) => {
  const simulator = useSelector((state: RootState) =>
    getSimulatorBySimId(state, { simId: props.simulatorId })
  );

  const [
    latestScreenshot,
    setLatestScreenshot,
  ] = useState<SimulatorScreenShotsDto | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  function resizeImage(image: any, modal: any) {
    const modalAspectRatio = modal.offsetWidth / modal.offsetHeight;
    const imageAspectRatio = image.naturalWidth / image.naturalHeight;

    let newWidth, newHeight;

    if (imageAspectRatio > modalAspectRatio) {
      newWidth = modal.offsetWidth;
      newHeight = modal.offsetWidth / imageAspectRatio;
    } else {
      newHeight = modal.offsetHeight;
      newWidth = modal.offsetHeight * imageAspectRatio;
    }

    image.style.width = newWidth + "px";
    image.style.height = newHeight + "px";
  }

  const modal = document.getElementById("modalContent");
  const image = document.getElementById("latestScreenshotImage");

  modal?.addEventListener("shown.bs.modal", () => {
    resizeImage(image, modal);
  });

  useEffect(() => {
    const getLatestScreenshots = () => {
      const url = `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/simulators/${props.simulatorId}/latest_screenshot`;

      axios
        .get(url, clubhouseApiHeadersV1())
        .then(async (res) => {
          pendoTrackEventWithProperties(ClubhouseEvents.LATEST_SCREENSHOT, {
            id: props.simulatorId,
            latestScreenshot: true,
          });
          setLatestScreenshot(res.data as SimulatorScreenShotsDto);
        })
        .catch((error) => {
          if (error.response.status === 404) {
            setLatestScreenshot(null);
            setErrorMessage(error.response.data);
          }
        });
    };
    if (!latestScreenshot) {
      getLatestScreenshots();
    }
  }, [latestScreenshot, props.simulatorId]);

  return (
    <Modal
      modalName={props.modalName}
      disableContentScrolling={true}
      hideFooter={true}
      hideHeader={false}
      modalSize="w-full h-full lg:w-5/6 2xl:w-2/3 5xl:w-1/3"
      title={
        latestScreenshot?.captureTimeUtc
          ? simulator?.name +
            " latest screenshot - " +
            DateTime.fromISO(latestScreenshot?.captureTimeUtc)?.toRelative()
          : simulator?.name + " latest screenshot"
      }
      body={
        <div id="scorecard" className="flex self-center max-w-5/6 max-h-5/6">
          {errorMessage ? (
            <div className="h-96 align-middle m-auto mt-12">{errorMessage}</div>
          ) : (
            <img
              src={`data:image/jpeg;base64,${latestScreenshot?.image}`}
              alt="Latest Screenshot"
              height={latestScreenshot?.height}
              id="latestScreenshotImage"
              className="flex m-auto"
            />
          )}
        </div>
      }
    />
  );
};

type LatestScreenshotModalProps = {
  title?: string;
  modalName: string;
  simulatorId: string;
};

LatestScreenshotModal.defaultProps = {
  title: "Get Scorecard",
};

export default LatestScreenshotModal;
