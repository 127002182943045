import { Round, StartRoundPayload, StartUnityGameDto } from "Models";
import { normalize } from "normalizr";
import { AppQueryConfig, MoveRoundPayload } from "QueryTypes";
import { round } from "./roundSchema";
import { RoundsEntitiesState } from "StoreTypes";
import { reduxQueryDefaultOptions } from "utils/queries/queryHelpers";
import { leagueQueryDefaultOptions } from "modules/leagues/leagueHelpers";

const urls = {
  roundById(roundId: string): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/rounds/${roundId}`;
  },
  startRound(facilityId: string, simulatorId: string): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/simulators/${simulatorId}/startround`;
  },
  moveRound(roundId: string): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/rounds/${roundId}/move/`;
  },
  startUnityGame(facilityId: string, simulatorId: string): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/unity/facility/${facilityId}/simulator/${simulatorId}/start_unity_game`;
  },
};

export const getRoundByIdQuery = (roundId: string): AppQueryConfig | null => {
  if (!roundId?.trim()) {
    console.log('roundId is falsy or empty');
    return null; // exit early if roundId is falsy or empty
  }
  console.log('getting round information for: ', roundId);

  return {
    url: urls.roundById(roundId),
    queryKey: `getRoundById:${roundId}`,
    options: { ...reduxQueryDefaultOptions() },
    transform: (responseJson: Round) => {
      return normalize(responseJson, round).entities;
    },
    update: {
      rounds: (oldValue, newValue): RoundsEntitiesState => ({
        ...oldValue,
        ...newValue,
      }),
    },
  }
}

export const makeStartRoundMutation = (
  facilityId: string,
  simulatorId: string,
  body: StartRoundPayload
): AppQueryConfig => ({
  url: urls.startRound(facilityId, simulatorId),
  queryKey: `startRound:${simulatorId}`,
  options: {
    ...reduxQueryDefaultOptions(),
    method: "POST",
  },
  body,
});

export const makeStartUnityGameMutation = (
  facilityId: string,
  simulatorId: string,
  body: StartUnityGameDto
): AppQueryConfig => ({
  url: urls.startUnityGame(facilityId, simulatorId),
  queryKey: `makeStartUnityGameMutation:${simulatorId}`,
  options: {
    ...leagueQueryDefaultOptions(),
    method: "POST",
  },
  body,
});

export const makeMoveRoundMutation = (
  roundId: string,
  body: MoveRoundPayload
): AppQueryConfig => ({
  url: urls.moveRound(roundId),
  options: {
    ...reduxQueryDefaultOptions(),
    method: "POST",
  },
  body,
});
