import { BsGrid } from "react-icons/bs";
import { FiUpload } from "react-icons/fi";
import { useCallback, useState } from "react";
import { Clubhouse_AdKitImageDto } from "Models";
import { useGetCurrentLocalFacilityId } from "modules/facility/facilityHooks";
import { getAdKitImages, uploadAdKitImages } from "./utils/adKitHelpers";
import AdKitImage from "./AdKitImage";
import ImageSkeleton from "components/ui/skeletonLoaders/imageSkeleton";

const AdKitImageManager: React.FC = () => {
  const currentFacilityId = useGetCurrentLocalFacilityId();

  const [selectPreviousImages, setSelectPreviousImages] = useState(false);
  const [showDeletedImages, setShotDeletedImages] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [adKitUploadedImages, setAdKitUploadedImages] = useState<
    Clubhouse_AdKitImageDto[]
  >([]);

  const handleViewPreviousImages = () => {
    setSelectPreviousImages(!selectPreviousImages);
    fetchAdKitImages();
  };

  const handleDeletedImages = async (checked: boolean) => {
    setShotDeletedImages(checked);
    let images = await getAdKitImages(currentFacilityId, checked);
    setAdKitUploadedImages(images);
  };

  const addFiles = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      let fileList = event.target.files;
      if (!fileList) return;

      uploadAdKitImages(
        currentFacilityId,
        Array.from(fileList).map((file) => ({
          file: file,
          fileName: file.name,
          preview: URL.createObjectURL(file),
          overrideWarnings: false,
          contentType: file.type,
          extension: file.name?.split(".").pop() || "",
        }))
      );
    },
    [currentFacilityId]
  );

  async function fetchAdKitImages(getDeletedImages = false) {
    setIsLoading(true);
    const images = await getAdKitImages(currentFacilityId, getDeletedImages);

    if (images) {
      setAdKitUploadedImages(images);
    }
    setIsLoading(false);
  }

  return (
    <div>
      <div className="space-y-2 justify-between justify-items-center flex mb-4 dark:text-gray-50">
        <div className="flex flex-col">
          <p className="text-md font-bold">Upload or Select Images</p>

          <p className="text-muted-foreground text-zinc-400 text-xs pb-2">
            Please upload or select images from the image library to get
            started.
          </p>
        </div>
        <div className="bg-white dark:bg-zinc-900 poppins p-1 text-xs text-gray-500 dark:text-gray-200 leading-none border border-gray-200 dark:border-gray-500 shadow-sm rounded-xl inline-flex">
          <button
            className={`${
              !selectPreviousImages
                ? "bg-brandGreen dark:bg-zinc-800 text-white dark:text-gray-200 rounded-lg inline-flex items-center transition-colors duration-300 ease-in focus:outline-none hover:dark-gray px-4 py-2"
                : " inline-flex items-center transition-colors duration-100 ease-out focus:outline-none hover:dark-gray rounded-lg px-4 py-2"
            }`}
            id="grid"
            onClick={() => {
              setSelectPreviousImages(false);
            }}
          >
            <FiUpload className="size-4" />
            <span className="ml-1">Upload</span>
          </button>
          <button
            className={` ${
              selectPreviousImages
                ? "bg-brandGreen dark:bg-zinc-800 text-white dark:text-gray-200 rounded-lg inline-flex items-center transition-colors duration-300 ease-in focus:outline-none hover:dark-gray px-4 py-2"
                : " inline-flex items-center transition-colors duration-100 ease-out focus:outline-none hover:dark-gray rounded-lg px-4 py-2"
            }`}
            id="list"
            onClick={() => {
              setSelectPreviousImages(true);
              handleViewPreviousImages();
            }}
          >
            <BsGrid className="size-4" />
            <span className="ml-1">Library</span>
          </button>
        </div>
      </div>

      {!selectPreviousImages ? (
        <label htmlFor="fileUpload" className="cursor-pointer">
          <div className="flex h-40 w-full flex-col items-center justify-center gap-y-2 border-2 border-dashed rounded-lg bg-gray-50 dark:bg-zinc-800 dark:text-gray-50 p-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="h-10 w-10 text-zinc-800 dark:text-gray-50 opacity-50"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 8.25H7.5a2.25 2.25 0 00-2.25 2.25v9a2.25 2.25 0 002.25 2.25h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25H15m0-3l-3-3m0 0l-3 3m3-3V15"
              />
            </svg>
            <p className="text-sm text-slate-500 dark:text-gray-50">
              Drag and drop your files here or click to browse images
            </p>
            <p className="text-sm text-slate-500 dark:text-gray-50">
              SVG, PNG, JPG or GIF (max. 5MB each)
            </p>
            <input
              type="file"
              multiple
              accept="image/*"
              id="fileUpload"
              className="hidden"
              onChange={addFiles}
            />
          </div>
        </label>
      ) : (
        <>
          <div
            key="restoreDeletedImages"
            className="text-xs dark:text-gray-50 rounded border border-gray-200 dark:border-gray-500 bg-white dark:bg-zinc-900 p-2 mb-2"
          >
            <input
              type="checkbox"
              className="border-gray-300 rounded disabled:bg-gray-300 dark:disabled:bg-zinc-900 text-brandGreen focus:ring-0 ring-opacity-0 border ml-2"
              onChange={(e) => handleDeletedImages(!showDeletedImages)}
              checked={showDeletedImages}
            />{" "}
            Include previously deleted images
          </div>
          <div className="grid grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-4 mt-6 mb-6">
            {isLoading && (
              <>
                <ImageSkeleton key={`placerholder1`} />
                <ImageSkeleton key={`placerholder2`} />
                <ImageSkeleton key={`placerholder3`} />
              </>
            )}
            {!isLoading &&
              adKitUploadedImages?.map((file, index) => (
                <AdKitImage
                  file={file}
                  index={index}
                  showDeletedImages={showDeletedImages}
                  callback={() => fetchAdKitImages()}
                />
              ))}
          </div>
        </>
      )}
    </div>
  );
};
export default AdKitImageManager;
