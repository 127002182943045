import { SimulatorState } from "Models";
import { getStateDisplay } from "utils/enums/enumHelper";
import { simStatusColorHelper } from "utils/icons/iconHelpers";

type OwnProps = {
  simulatorState: SimulatorState;
};

const SimStatusName: React.FC<OwnProps> = ({ simulatorState }) => {
  return (
    <div className={simulatorState ? simStatusColorHelper[simulatorState.status] : "text-blue-500"}>
      {simulatorState ? getStateDisplay(simulatorState) : "UNKNOWN"}
    </div>
  );
};
export default SimStatusName;
