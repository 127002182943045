import { SimulatorDto } from "Models";
import { useSelector } from "react-redux";
import { getSimulatorsByCurrentFacility } from "modules/simulator/simulatorSelectors";

const CreateAdKitStepThree: React.FC = () => {
  const simulators = useSelector(getSimulatorsByCurrentFacility);

  let eligibleAdKitSimulators = simulators.filter(
    (simulator) =>
      simulator.capabilities?.canRespondToAdKit === true &&
      simulator.capabilities?.hasAdKitSubscription === true
  );

  return (
    <div>
      <div className="space-y-2">
        <p className="text-md font-bold">Assign to Simulators</p>
        <p className="text-muted-foreground text-zinc-400 text-xs pb-2">
          Select which simulators you want to assign these images to.
        </p>
      </div>
      <div className="h-auto">
        <div className="grid gap-4 sm:grid-cols-2 lg:grid-cols-3">
          {eligibleAdKitSimulators.length === 0 && (
            <div className="flex items-center space-x-3 rounded-lg border p-4">
              <p className="text-sm font-medium flex-1 cursor-pointer">
                No simulators are eligible for AdKit assignment.
              </p>
            </div>
          )}
          {eligibleAdKitSimulators.map((simulator: SimulatorDto) => (
            <div className="flex items-center space-x-3 rounded-lg border p-4">
              <input
                type="checkbox"
                aria-hidden="false"
                id={simulator.id}
                value={simulator.id}
              />
              <label
                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 flex-1 cursor-pointer"
                htmlFor={simulator.id}
              >
                {simulator.name}
              </label>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default CreateAdKitStepThree;
