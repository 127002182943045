import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ClubhouseAdKitDto } from "Models";

export type AdKitState = {
  selectedAdKit: ClubhouseAdKitDto | never[];
};

const initialState: AdKitState = {
  selectedAdKit: [],
};

const slice = createSlice({
  name: "adkit",
  initialState,
  reducers: {
    setSelectedAdKit(
      state,
      action: PayloadAction<{
        selectedAdKit: ClubhouseAdKitDto;
      }>
    ): void {
      state.selectedAdKit = action.payload.selectedAdKit;
    },
  },
});

const { actions, reducer: adKitReducer } = slice;

const { setSelectedAdKit } = actions;

export { adKitReducer, setSelectedAdKit };
