import {
  EventHandicapMode,
  EventMensTeeType,
  EventPinType,
  EventsWindSpeed,
  EventWomensTeeType,
  Weather,
  WindDirection,
  AutoConcede,
  AutoContinue,
  AutoGimme,
  RoundType,
  Gender,
  GimmeDistance,
  HandicapMode,
  HandicapPercentage,
  PinType,
  Ranges,
  ScoringType,
  SimStatus,
  TeeType,
  WindSpeed,
  Rounds,
  EventCompetitionType,
  Ground,
  EventGimmeDistance,
  Sky,
  TimeOfDay,
  LeagueType,
  LeagueScoring,
  LeagueHandicapMethod,
  LeagueTeamSize,
  LeagueRoundCompetitionType,
  TeamPlayFormat,
  MatchPlayAssignment,
  MatchPlayStyle,
  MatchPlayTeamStyle,
  LeagueHandicapMode,
  UnityGames,
  NoPuttMode,
} from "types/enums";
import { Option, SimulatorState } from "Models";


const stringIsNumber = (value: any) => isNaN(Number(value)) === false;

export function titleCase(str: string) {
  return str
    ? str
        .toString()
        .replaceAll("_", " ")
        .toLowerCase()
        .split(" ")
        .map(function (word) {
          return word.replace(word[0], word[0].toUpperCase());
        })
        .join(" ")
    : "";
}

export function camelCasetoTitleCase(str: string) {
  return str
    ? str
        .toString()
        .replaceAll("_", " ")
        .replace(/([A-Z])/g, " $1")
        .replace(/^./, function (str) {
          return str.toUpperCase();
        })
        .toLowerCase()
        .split(" ")
        .map(function (word) {
          return word.replace(word[0], word[0]?.toUpperCase());
        })
        .join(" ")
    : "";
}

export function enumNameToLabel(str: string) {
  return (
    str.toString().charAt(0).toUpperCase() +
    str
      .slice(1)
      .replace(/([A-Z])/g, " $1")
      .trim()
  );
}

export function enumToArray(enumme: any) {
  return Object.keys(enumme)
    .filter(stringIsNumber)
    .map((key) => enumme[key]);
}

export function roundConfigEnumToReactSelectOptions<T extends Record<string, string | number>>(
  enumme: T,
  format: boolean = true
): Option<T[keyof T]>[] {
  const arrayObjects: Option<T[keyof T]>[] = [];

  for (const [propertyKey, propertyValue] of Object.entries(enumme)) {
    if (!Number.isNaN(Number(propertyKey))) {
      continue;
    }
    arrayObjects.push({
      value: propertyValue as T[keyof T],
      label: format
        ? titleCase(propertyKey as string)
        : (propertyKey as string),
    });
  }

  return arrayObjects;
}

export const greenStimpOptions: Option[] = [
  { label: "8",    value: 8    },
  { label: "8.5*", value: 8.5  },
  { label: "9",    value: 9    },
  { label: "9.5",  value: 9.5  },
  { label: "10",   value: 10   },
  { label: "10.5", value: 10.5 },
  { label: "11",   value: 11   },
  { label: "11.5", value: 11.5 },
  { label: "12",   value: 12   },
  { label: "12.5", value: 12.5 },
  { label: "13",   value: 13   },
  { label: "13.5", value: 13.5 },
  { label: "14",   value: 14   }
];

export const trueFalseOptions: Option[] = [
  { label: "On", value: true },
  { label: "Off", value: false },
];

export const roundConfigEnumsAsReactSelectOptions = {
  autoConcede: roundConfigEnumToReactSelectOptions(AutoConcede),
  autoContinue: roundConfigEnumToReactSelectOptions(AutoContinue),
  autoGimme: roundConfigEnumToReactSelectOptions(AutoGimme),
  gameType: roundConfigEnumToReactSelectOptions(RoundType),
  gender: roundConfigEnumToReactSelectOptions(Gender),
  gimmeDistance: roundConfigEnumToReactSelectOptions(GimmeDistance),
  eventGimmeDistance: roundConfigEnumToReactSelectOptions(EventGimmeDistance),
  handicapMode: roundConfigEnumToReactSelectOptions(HandicapMode),
  leagueHandicapMode: roundConfigEnumToReactSelectOptions(LeagueHandicapMode),
  handicapPercentage: roundConfigEnumToReactSelectOptions(HandicapPercentage),
  pinType: roundConfigEnumToReactSelectOptions(PinType),
  ranges: roundConfigEnumToReactSelectOptions(Ranges),
  scoringType: roundConfigEnumToReactSelectOptions(ScoringType),
  teeType: roundConfigEnumToReactSelectOptions(TeeType),
  windSpeed: roundConfigEnumToReactSelectOptions(WindSpeed),
  rounds: roundConfigEnumToReactSelectOptions(Rounds),
  eventCompetitionType: roundConfigEnumToReactSelectOptions(
    EventCompetitionType
  ),
  eventMensTee: roundConfigEnumToReactSelectOptions(EventMensTeeType),
  eventWomensTee: roundConfigEnumToReactSelectOptions(EventWomensTeeType),
  eventPinType: roundConfigEnumToReactSelectOptions(EventPinType),
  groundConditions: roundConfigEnumToReactSelectOptions(Ground),
  windDirection: roundConfigEnumToReactSelectOptions(WindDirection, false),
  eventsWindSpeed: roundConfigEnumToReactSelectOptions(EventsWindSpeed),
  eventsHandicapMode: roundConfigEnumToReactSelectOptions(EventHandicapMode),
  weather: roundConfigEnumToReactSelectOptions(Weather),
  skyConditions: roundConfigEnumToReactSelectOptions(Sky),
  timeOfDay: roundConfigEnumToReactSelectOptions(TimeOfDay),
  stimpSetting: greenStimpOptions,
  mulligan: trueFalseOptions,
  autoDrop: trueFalseOptions,
  leagueType: roundConfigEnumToReactSelectOptions(LeagueType),
  leagueRoundCompetitionType: roundConfigEnumToReactSelectOptions(
    LeagueRoundCompetitionType
  ),
  leagueScoring: roundConfigEnumToReactSelectOptions(LeagueScoring),
  leagueHandicap: roundConfigEnumToReactSelectOptions(LeagueHandicapMethod),
  leagueTeamSize: roundConfigEnumToReactSelectOptions(LeagueTeamSize),
  teamPlayFormat: roundConfigEnumToReactSelectOptions(TeamPlayFormat),
  matchPlayAssignment: roundConfigEnumToReactSelectOptions(MatchPlayAssignment),
  matchPlayStyle: roundConfigEnumToReactSelectOptions(MatchPlayStyle),
  matchPlayTeamStyle: roundConfigEnumToReactSelectOptions(MatchPlayTeamStyle),
  unityGames: roundConfigEnumToReactSelectOptions(UnityGames),
  noPuttMode: roundConfigEnumToReactSelectOptions(NoPuttMode),
};

export const serviceRequestEnumDisplayName = (str: string) => {
  const name = str?.slice(11)?.toLowerCase();
  return name?.charAt(0)?.toUpperCase() + name.slice(1);
};

export const unityGameEnumDisplayNameKeyedByString: Record<UnityGames, string>  = {
  [UnityGames.GAME_BALLSTRIKING_NONE]:              "",
  [UnityGames.GAME_BALLSTRIKING_FORE]:              "Fore",
  [UnityGames.GAME_BALLSTRIKING_BENDIT]:            "Bend It",
  [UnityGames.GAME_BALLSTRIKING_CONQUEST]:          "Conquest",
  [UnityGames.GAME_BALLSTRIKING_RAZORSEDGE]:        "Razors Edge",
  [UnityGames.GAME_BARARCADE_NONE]:                 "",
  [UnityGames.GAME_BARARCADE_DARTS]:                "Darts",
  [UnityGames.GAME_BARARCADE_CORNHOLE]:             "Cornhole",
  [UnityGames.GAME_BARARCADE_BEERPONG]:             "Beer Pong",
  [UnityGames.GAME_BARARCADE_SKEEBALL]:             "Skeeball",
  [UnityGames.GAME_MINIGOLF_NONE]:                  "",
  [UnityGames.GAME_MINIGOLF_GALACTICGREENS]:        "Galactic Greens",
  [UnityGames.GAME_MINIGOLF_LOSTLAGOON]:            "Lost Lagoon",
  [UnityGames.GAME_MINIGOLF_MYSTERYREEF]:           "Mystery Reef",
  [UnityGames.GAME_PARTY_CTP]:                      "Closest to the Pin Party",
  [UnityGames.GAME_PARTY_LD]:                       "Long Drive Party",
  [UnityGames.GAME_SOCCER]:                         "Soccer",
  [UnityGames.GAME_BALLSTRIKING_DIALEDIN]:          "Dialed In",
  [UnityGames.GAME_BALLSTRIKING_GOLFERABILITYTEST]: "Golfer Ability Test (GAT)",
  [UnityGames.GAME_BALLSTRIKING_FLAGHUNTER]:        "Flag Hunter",
  [UnityGames.GAME_MULTISPORT_STICKBALL]:           "Stickball",
  [UnityGames.GAME_MULTISPORT_BASEBALL]:            "Baseball",
  [UnityGames.GAME_MULTISPORT_FOOTBALL]:            "Football",
  [UnityGames.GAME_MULTISPORT_TEXASGOLFEM]:         "Texas Golf'em",
  [UnityGames.GAME_MULTISPORT_PIRATEPARTY]:         "Pirate Party",
};


export const simStatusEnumDisplayNameKeyedByString: Record<SimStatus, string>  = {
  [SimStatus.STATUS_SIM_OFF]:             "Off",
  [SimStatus.STATUS_SIM_START]:           "Starting",
  [SimStatus.STATUS_SETUP_ENTER]:         "Entering Setup",
  [SimStatus.STATUS_SETUP_IDLE]:          "Setup Idle",
  [SimStatus.STATUS_SETUP_EXIT]:          "Exiting Setup",
  [SimStatus.STATUS_RANGE_ENTER]:         "Entering Range",
  [SimStatus.STATUS_RANGE_SHOT]:          "Range Taking Shot",
  [SimStatus.STATUS_RANGE_IDLE]:          "Range Idle",
  [SimStatus.STATUS_RANGE_EXIT]:          "Range Exit",
  [SimStatus.STATUS_RND_START]:           "Round Starting",
  [SimStatus.STATUS_RND_SHOT]:            "Round Taking Shot",
  [SimStatus.STATUS_RND_IDLE]:            "Round Idle",
  [SimStatus.STATUS_RND_HOLE_END]:        "Hole Ending",
  [SimStatus.STATUS_RND_END]:             "Round Ending",
  [SimStatus.STATUS_SIM_EXIT]:            "Sim Exiting",
  [SimStatus.STATUS_TIMER_PAUSE]:         "Timer Pausing",
  [SimStatus.STATUS_TIMER_UNPAUSE]:       "Timer Unpausing",
  [SimStatus.STATUS_TIMER_EXPIRED]:       "Timer Expired",
  [SimStatus.STATUS_MAIN_MENU]:           "Main Menu",
  [SimStatus.STATUS_COURSE_MENU]:         "Course Selection Menu",
  [SimStatus.STATUS_COURSE_SETUP]:        "Round Setup Menu",
  [SimStatus.STATUS_MINIGAMES_MENU]:      "Minigames Menu",
  [SimStatus.STATUS_UNITY_GAME_RUNNING]:  "Playing Minigame",
  [SimStatus.STATUS_UNITY_GAME_FINISHED]: "Finished Playing Minigame",
  [SimStatus.STATUS_OVERDRIVE_RANGE]:     "Playing Overdrive Range",
};

export function getEnumKeyByEnumValue<T extends { [index: string]: string }>(
  myEnum: T,
  enumValue: string
): keyof T | null {
  let keys = Object.keys(myEnum).filter((x) => myEnum[x] === enumValue);
  return keys.length > 0 ? keys[0] : null;
}

export function getStateDisplay(state: SimulatorState): string {
  if (state.status === SimStatus.STATUS_UNITY_GAME_RUNNING || state.status === SimStatus.STATUS_UNITY_GAME_FINISHED) { 
    let game = "";
    if (state.game) {
      game = unityGameEnumDisplayNameKeyedByString[state.game];
    }
    return `${simStatusEnumDisplayNameKeyedByString[state.status]}` + ( game !== "" ? ` - ${game}` : ""); ;
  }

  return simStatusEnumDisplayNameKeyedByString[state.status];
};
