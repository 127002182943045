export enum AutoConcede {
  OFF = 0,
  DOUBLE_PAR = 1,
  TEN_STROKES = 2,
  BOGEY = 3,
  DOUBLE_BOGEY = 4,
  TRIPLE_BOGEY = 5,
}
export enum AutoContinue {
  OFF = 0,
  ONE_SECOND = 1,
  FOUR_SECONDS = 4,
  SEVEN_SECONDS = 7,
  TEN_SECONDS = 10,
  FIFTEEN_SECONDS = 15,
  INSTANT = -1,
}
export enum AutoGimme {
  "OFF" = 0,
  "1STROKE" = 1,
  "2STROKE" = 2,
  "3STROKE" = 3,
}
export enum Role {
  USER = 0,
  ADMIN = 1,
}
export enum ExitSimType {
  EXIT = 0,
  SHUTDOWN = 1,
  RESTART = 2,
}
export enum FacilityMessageType {
  PlayerLogin = 0,
  PlayerLogout = 1,
  LockerToken = 2,
}
export enum BrowserMessageType {
  SEND_ROUND_RANGE_TO_SIM = 0,
  SEND_ROUND_RANGE_TO_SIMS = 1,
}
export enum FacilityResponseMessageType {
  PlayerLoginReply = 0,
  PlayerLogoutReply = 1,
}
export enum RoundType {
  RANGE = 0,
  ROUND = 1,
  GAME = 2,
}
export enum Gender {
  MALE = 0,
  FEMALE = 1,
}
export enum GimmeDistance {
  OFF = 0,
  "3FT" = 1,
  "6FT" = 2,
  "9FT" = 3,
  "12FT" = 4,
}
export enum HandicapMode {
  ABSOLUTE = 0,
  RELATIVE = 1,
  OFF = 2,
}
export enum LeagueHandicapMode {
  COURSE = 0,
  PLAYER = 3,
}
export enum HandicapPercentage {
  "100%" = "100",
  "90%" = "90",
  "80%" = "80",
  "70%" = "70",
}
export enum PinType {
  NOVICE = 0,
  INTERMEDIATE = 1,
  ADVANCED = 2,
}
export enum Ranges {
  MOUNTAIN_VENUE = 1,
  TROPICAL = 12,
  CANYON = 18,
  WATERFALLS = 13,
  TARGET_GREEN = 16,
  LONG_DRIVE_GRID = 14,
  LONG_FAIRWAY = 15,
  SAND_20_YDS = 11,
  CHIP_15_YDS = 9,
  CHIP_40_YDS = 10,
  DOWNHILL_PUTT = 6,
  UPHILL_PUTT = 5,
  PUTT_RIGHT_BRK = 7,
  PUTT_LEFT_BRK = 8,
  PUTT_5_FT = 2,
  PUTT_15_FT = 3,
  PUTT_45_FT = 4,
}
export enum RoundStatus {
  Begin = 0,
  InProgress = 1,
  End = 2,
}
export enum ScoringType {
  STROKE = 0,
  MATCH = 1,
  SCRAMBLE = 7,
}
export enum NoPuttMode {
  OFF = 0,
  ON = 1,
}
export enum ScoringTypes {
  SCORING_STROKE = 0,
  SCORING_MATCH = 1,
  SCORING_SKINS = 3,
  SCORING_SCRAMBLE = 7,
  SCORING_BINGO = 8,
  SCORING_LONG_DRIVE = 9,
  SCORING_CLOSEST_TO_PIN = 10,
  SCORING_STABLEFORD = 11,
  SCORING_STROKE_CUSTOM = 12,
  SCORING_STROKE_DRILLS = 13,
}
export enum ServiceRequestType {
  CC_REQUEST_FOOD = 1,
  CC_REQUEST_HELP = 2,
  CC_REQUEST_STUCK = 3,
  CC_EVENT_HELP = 4,
  CC_LIVE_SCREENSHOT_HELP = 5,
}

export enum SimStatus {
  STATUS_SIM_OFF = 0,
  STATUS_SIM_START = 1,
  STATUS_SETUP_ENTER = 2,
  STATUS_SETUP_IDLE = 3,
  STATUS_SETUP_EXIT = 4,
  STATUS_RANGE_ENTER = 5,
  STATUS_RANGE_SHOT = 6,
  STATUS_RANGE_IDLE = 7,
  STATUS_RANGE_EXIT = 8,
  STATUS_RND_START = 9,
  STATUS_RND_SHOT = 10,
  STATUS_RND_IDLE = 11,
  STATUS_RND_HOLE_END = 12,
  STATUS_RND_END = 13,
  STATUS_SIM_EXIT = 14,
  STATUS_TIMER_PAUSE = 15,
  STATUS_TIMER_UNPAUSE = 16,
  STATUS_TIMER_EXPIRED = 17,
  STATUS_MAIN_MENU = 18,
  STATUS_COURSE_MENU = 19,
  STATUS_COURSE_SETUP = 20,
  STATUS_MINIGAMES_MENU = 21,
  STATUS_UNITY_GAME_RUNNING = 22,
  STATUS_UNITY_GAME_FINISHED = 23,
  STATUS_OVERDRIVE_RANGE = 24
}

export enum TimerStatus {
  ON = 0,
  OFF = 1,
  RUNNING = 2,
  PAUSED = 3,
  EXPIRED = 4,
  CLEARED = 5,
}
export enum SimulatorMessageType {
  SIM_STATUS = 0,
  SIM_START_GAME = 1,
  SIM_END_GAME = 2,
  TIMER_CONTROL = 3,
  SIM_EXIT = 4,
  SEND_MESSAGE = 5,
  SCORECARD = 6,
  COURSE_LIST = 7,
  SERVICE_REQUEST = 8,
  MESSAGE_PROCESSED = 9,
  TIMER_UPDATE = 10,
  ROUND_BEGAN = 11,
  ROUND_UPDATE = 12,
  ROUND_ENDED = 13,
  ROUND_PLAYER_UPDATE = 14,
  USER_SHOT = 27,
  LATEST_SCREENSHOT_BYTES = 39,
  ADKIT_ACTIVATED = 43,
  UNITY_GAME_STARTED = 46
}
export enum SimulatorResponseMessageType {
  SIM_STATUS_REPLY = 0,
  SIM_START_GAME_REPLY = 1,
  SIM_END_GAME_REPLY = 2,
  TIMER_CONTROL_REPLY = 3,
  SIM_EXIT_REPLY = 4,
  SEND_MESSAGE_REPLY = 5,
  SCORECARD_REPLY = 6,
  COURSE_LIST_REPLY = 7,
  SERVICE_REQUEST_REPLY = 8,
  MESSAGE_PROCESSED_REPLY = 9,
  TIMER_UPDATE_REPLY = 10,
  ROUND_BEGAN_REPLY = 11,
  ROUND_UPDATE_REPLY = 12,
  ROUND_ENDED_REPLY = 13,
  ROUND_PLAYER_UPDATE_REPLY = 14,
}
export enum SwingMeterType {
  Default = 5,
}
export enum Team {
  TEAM1 = 1,
  TEAM2 = 2,
  TEAM3 = 3,
}
export enum TeeType {
  RED = 0,
  WHITE = 1,
  BLUE = 2,
  BLACK = 3,
  GOLD = 4,
  JUNIOR = 5,
  SKILL = 6,
}
export enum TimerAction {
  ADD_TIME = 0,
  PAUSE = 1,
  CLEAR_DISABLE = 2,
  UNPAUSE = 3,
  CLEAR_LOCK = 4,
}
export enum WindSpeed {
  CALM = 0,
  LIGHT = 1,
  MODERATE = 2,
  STRONG = 3,
  VERY_STRONG = 4,
  OFF = 5,
  FIVE_MPH_STEADY = 6,
  TEN_MPH = 7,
  FIFTEEN_MPH = 8,
  TWENTY_MPH = 9,
  TWENTY_FIVE_MPH = 10,
  THIRTY_MPH = 11,
  THIRTY_FIVE_MPH = 12,
}
export enum MotionVariantType {
  HOVER = "hover",
  PRESSED = "pressed",
  INITIAL = "initial",
  EXIT = "exit",
  REST = "rest",
  HIDDEN = "hidden",
  SHOW = "show",
  VISIBLE = "visible",
}
export enum Rounds {
  ONE = 1,
  TWO = 2,
  THREE = 3,
}
export enum EventCompetitionType {
  STROKE = "tournament",
  LONG_DRIVE = "long_drive",
  CLOSEST_TO_PIN = "closest_to_pin",
}
export enum Ground {
  VERY_DRY = 1,
  DRY = 2,
  NORMAL = 0,
  WET = 3,
  VERY_WET = 4,
}
export enum WindDirection {
  N = 270,
  NE = 225,
  E = 180,
  SE = 135,
  S = 90,
  SW = 45,
  W = 0,
  NW = 315,
}
export enum Sky {
  CLEAR = 0,
  FAIR = 1,
  PARTLY_CLOUDY = 2,
  CLOUDY = 3,
  FOG = 4,
}
export enum Weather {
  SUNNY = 0,
  MOSTLY_SUNNY = 1,
  MOSTLY_CLOUDY = 2,
  CLOUDY = 3,
  FOG = 4,
}
export enum TimeOfDay {
  MORNING = 0,
  AFTERNOON = 1,
  DUSK = 2,
  NIGHT = 3,
}
export enum EventsWindSpeed {
  CALM = 0,
  LIGHT = 1,
  MODERATE = 2,
  STRONG = 3,
  VERY_STRONG = 4,
}

export enum EventHandicapMode {
  COURSE_HANDICAP = "absolute",
  OFF = "relative",
}
export enum RoundPaceStatus {
  BEHIND_SCHEDULE = 0,
  ON_TRACK = 1,
  AHEAD_OF_SCHEDULE = 2,
  OFF = 3,
}
export enum EventMensTeeType {
  BLACK = "black",
  GOLD = "gold",
  BLUE = "blue",
  WHITE = "white",
  RED = "red",
  JUNIOR = "junior",
  SKILL = "skill",
}
export enum EventWomensTeeType {
  BLACK = "black",
  GOLD = "gold",
  BLUE = "blue",
  WHITE = "white",
  RED = "red",
  JUNIOR = "junior",
  SKILL = "skill",
}
export enum EventPinType {
  NOVICE = "novice",
  INTERMEDIATE = "intermediate",
  ADVANCED = "advanced",
}
export enum EventGimmeDistance {
  OFF = 0,
  "3FT" = 3,
  "6FT" = 6,
  "9FT" = 9,
  "12FT" = 12,
}

export enum DefaultEventSettings {
  pin = PinType.NOVICE,
  sky = Weather.SUNNY,
  ground = Ground.DRY,
  mensTee = TeeType.BLUE,
  womensTee = TeeType.RED,
}

export enum HoleType {
  ALBATROSS = "-3",
  EAGLE = "-2",
  BIRDIE = "-1",
  PAR = "0",
  BOGEY = "1",
  DOUBLE_BOGEY = "2",
}

export enum LeagueType {
  TEAM = 1,
  INDIVIDUAL = 2,
}

export enum LeagueScoring {
  STROKE_PLAY = 1,
  MATCH_PLAY = 2,
  SCRAMBLE = 3,
}

export enum LeagueHandicapMethod {
  NET = 1,
  GROSS = 2,
}

export enum LeagueTeamSize {
  TWO = 2,
  THREE = 3,
  FOUR = 4,
}

export enum LeagueRoundCompetitionType {
  STROKE_PLAY = 1,
  MATCH_PLAY = 2,
}

export enum HoleResult {
  PAR = 0,
  BOGEY = 1,
  DOUBLE_BOGEY = 2,
  TRIPLE_BOGEY = 3,
  DOUBLE_EAGLE = -3,
  EAGLE = -2,
  BIRDIE = -1,
}

export enum TeamPlayerStatus {
  NORMAL = 0,
  TEMPORARY_SUB = 1,
  REMOVED = 2,
  REPLACED = 3,
  REPLACEMENT = 4,
}

export enum Handedness {
  RIGHT = 0,
  LEFT = 1,
}

export enum MatchHoleParticipantResult {
  TIED = 0,
  PARTICIPANT_1 = 1,
  PARTICIPANT_2 = 2,
}

export enum MatchResult {
  LOST = 0,
  TIED = 1,
  WON = 2,
}

export enum ScorecardScoringType {
  STROKE = 0,
  MATCH = 1,
  SCRAMBLE = 7,
  LONG_DRIVE = 9,
  CLOSEST_to_PIN = 10,
  STABLEFORD = 11,
  MATCH_NASSAU = 1001,
  MATCH_SCOTCH_FOURSOME = 1002,
}

export enum TeamPlayFormat {
  FOUR_BALL = 1,
}

export enum MatchPlayStyle {
  AD_HOC = 0,
}

export enum MatchPlayTeamStyle {
  AS_TEAM = 0,
}

export enum MatchPlayAssignment {
  MANUAL = 0,
}

export enum MatchParticipantNumber {
  ONE = 1,
  TWO = 2,
}

export enum MatchParticipantStatus {
  NONE = 0,
  WAITING = 1,
  PLAYING = 2,
  FINISHED = 3,
}
export enum MatchStatus {
  NONE = 0,
  CREATED = 1,
  UPDATED = 2,
  WAITING = 3,
  PLAYING = 4,
  FINISHED_PLAYING = 5,
  SCORED = 6,
  FINISHED = 7,
}
export enum UnityGames {
  GAME_BALLSTRIKING_NONE = 0,
  GAME_BALLSTRIKING_FORE = 1,
  GAME_BALLSTRIKING_BENDIT = 2,
  GAME_BALLSTRIKING_CONQUEST = 3,
  GAME_BALLSTRIKING_RAZORSEDGE = 4,
  GAME_BARARCADE_NONE = 5,
  GAME_BARARCADE_DARTS = 6,
  GAME_BARARCADE_CORNHOLE = 7,
  GAME_BARARCADE_BEERPONG = 8,
  GAME_BARARCADE_SKEEBALL = 9,
  GAME_MINIGOLF_NONE = 10,
  GAME_MINIGOLF_GALACTICGREENS = 11,
  GAME_MINIGOLF_LOSTLAGOON = 12,
  GAME_MINIGOLF_MYSTERYREEF = 13,
  GAME_PARTY_CTP = 14,
  GAME_PARTY_LD = 15,
  GAME_SOCCER = 16,
  GAME_BALLSTRIKING_DIALEDIN = 17,
  GAME_BALLSTRIKING_GOLFERABILITYTEST = 18,
  GAME_BALLSTRIKING_FLAGHUNTER = 19,
  GAME_MULTISPORT_STICKBALL = 20,
  GAME_MULTISPORT_BASEBALL = 21,
  GAME_MULTISPORT_FOOTBALL = 22,
  GAME_MULTISPORT_TEXASGOLFEM = 23,
  GAME_MULTISPORT_PIRATEPARTY = 24,
}

export enum AdKitKey {
  TEE_SIGN_3D = 1,
  HOLE_LOAD_AD = 2,
  SCORECARD_AD = 3,
  SPONSOR_AD_3_IN_GAME = 4,
  SPONSOR_AD_2_POST_SHOT = 5,
  SPONSOR_AD_1_SETUP = 6,
  RANGE_FAIRWAY_OVERLAY = 7,
  RANGE_TEE_OVERLAY = 8,
  SKILL_EVENT_TEE_OVERLAY = 9,
  ROUND_TEE_OVERLAY = 10,
  LEADERBOARD = 11,
}
