import type { HoleBooleanMap, SluggedId } from "Models";
import { windowsFileNameXmlRegex, descriptionSchema, nameSchema, sluggedIdValidator, holeBooleanMapSchema } from "entities/common";
import { z } from "zod";
import { AdKitKey } from "types/enums";


type CreateAdKitLocationInstanceInternalDto = {
  imageId: SluggedId; // required integer
  overrideWarnings: boolean; // required boolean
  holeNumber: number; // required integer (0 means it doesn't show up on a specific hole; valid values 0–18)
  optionalImageFilePathOverride?: string | null; // optional string (null/undefined if not provided, max length 100)
  orderLocation?: number; // optional integer with default -1 if not provided (range: -1 to int.MaxValue)
  widthAdjustment?: number; // optional integer with default -1 (range: -1 to int.MaxValue)
  heightAdjustment?: number; // optional integer with default -1 (range: -1 to int.MaxValue)
};

type CreateAdKitAdLocationInternalDto = {
  adKitMetaDataId: AdKitKey;
  enabledHoles?: HoleBooleanMap;
  overrideWarnings: boolean;
  adInstances?: CreateAdKitLocationInstanceInternalDto[];
};


type CreateAdKitInternalDto = {
  name: string;
  description?: string | null;
  fileName?: string;
  transitionDelay?: number;
  transitionFadeIn?: number;
  transitionFadeOut?: number;
  adLocations?: CreateAdKitAdLocationInternalDto[];
};


type UpdateAdKitInternalDto = {
  name?: string;
  description?: string | null;
  fileName?: string;
  transitionDelay?: number;
  transitionFadeIn?: number;
  transitionFadeOut?: number;
  restoreDeleted: boolean;
};

const CreateAdKitLocationInstanceDtoRawSchema = z.object({
  // Required and must be an integer
  imageId: sluggedIdValidator,
  // Required boolean
  overrideWarnings: z.boolean(),
  // Must be an integer between 0 and 18 (inclusive)
  holeNumber: z.number().int().min(0).max(18).default(0),
  // Optional string that may be null and, if provided, cannot exceed 100 characters
  optionalImageFilePathOverride: z.string()
  .max(100, { message: "optionalImageFilePathOverride must be 100 characters or fewer" })
  .regex(windowsFileNameXmlRegex, { message: "optionalImageFilePathOverride must be a valid Windows file name" })
  .optional()
  .nullable(),
  // Optional integer with a default value of -1 (must be >= -1)
  orderLocation: z.number().int().min(-1).default(-1),
  // Optional integer with a default value of -1 (must be >= -1)
  widthAdjustment: z.number().int().min(-1).default(-1),
  // Optional integer with a default value of -1 (must be >= -1)
  heightAdjustment: z.number().int().min(-1).default(-1),
});


const CreateAdKitLocationDtoRawSchema = z.object({
  adKitMetaDataId: z.nativeEnum(AdKitKey),
  // enabledHoles is optional. If provided, it must adhere to the HoleBooleanMapSchema.
  enabledHoles: z.optional(holeBooleanMapSchema),
  overrideWarnings: z.boolean(),
  adInstances: z.optional(z.array(CreateAdKitLocationInstanceDtoRawSchema)),
});


export const CreateAdKitLocationInstanceDtoSchema: z.ZodType<CreateAdKitLocationInstanceInternalDto, z.ZodTypeDef, unknown> = 
CreateAdKitLocationInstanceDtoRawSchema.transform(
  (data): CreateAdKitLocationInstanceInternalDto => ({
    ...data
  })
);

export const CreateAdKitLocationDtoSchema: z.ZodType<CreateAdKitAdLocationInternalDto, z.ZodTypeDef, unknown> = 
CreateAdKitLocationDtoRawSchema.transform(
  (data): CreateAdKitAdLocationInternalDto => ({
    ...data,
  })
);


export type CreateAdKitLocationInstanceDto = z.infer<typeof CreateAdKitLocationInstanceDtoSchema>;
export type CreateAdKitLocationDto = z.infer<typeof CreateAdKitLocationDtoSchema>;

const CreateAdKitDtoRawSchema = z.object({
  ...nameSchema,
  ...descriptionSchema,
  fileName: z.string()
      .max(100, { message: "fileName must be 100 characters or fewer" })
      .regex(windowsFileNameXmlRegex, { message: "fileName must be a valid Windows file name and end in .xml" }),
    
  transitionDelay: z.number().optional(),
  transitionFadeIn: z.number().optional(),
  transitionFadeOut: z.number().optional(),
  adLocations: z.optional(z.array(CreateAdKitLocationDtoSchema)),
});

const UpdateAdKitDtoRawSchema = z.object({
  ...nameSchema,
  ...descriptionSchema,
  fileName: z.string()
      .max(100, { message: "fileName must be 100 characters or fewer" })
      .regex(windowsFileNameXmlRegex, { message: "fileName must be a valid Windows file name and end in .xml" })
      .optional(),
    
  transitionDelay: z.number().optional(),
  transitionFadeIn: z.number().optional(),
  transitionFadeOut: z.number().optional(),
  restoreDeleted: z.boolean()
}).extend({
  name: nameSchema.name.optional(), // overrides name to be optional
});

export const CreateAdKitDtoSchema: z.ZodType<CreateAdKitInternalDto, z.ZodTypeDef, unknown> = 
CreateAdKitDtoRawSchema.transform(
  (data): CreateAdKitInternalDto => ({
    ...data,
  })
);

export const UpdateAdKitDtoSchema: z.ZodType<UpdateAdKitInternalDto, z.ZodTypeDef, unknown> = 
UpdateAdKitDtoRawSchema.transform(
  (data): UpdateAdKitInternalDto => ({
    ...data,
  })
);

export type CreateAdKitDto = z.infer<typeof CreateAdKitDtoSchema>;
export type UpdateAdKitDto = z.infer<typeof UpdateAdKitDtoSchema>;
