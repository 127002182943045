import axios from "axios";
import useAppDispatch from "utils/hooks/useAppDispatch";
import { setIsAuthenticated, setUserId } from "modules/user/userSlice";
import {
  clearJWTFromStorage,
  jwtHasValidExpiration,
  getConvertToUserToken,
  getJWTFromLocalStorage,
} from "utils/tokens/tokenHelpers";
import { showCustomNotification } from "utils/notifications/notificationHelpers";
import { makeAgHandicapByUserIdMutation } from "./userQueries";
import { EntitiesState, QueryState } from "redux-query";
import { RunMutation, useMutation } from "redux-query-react";
import { AgLockerUpdateBody } from "QueryTypes";
import { LocalStorageItems } from "utils/hooks/localStorage";
import { v4 as uuidv4 } from "uuid";
import { useEffect, useState } from "react";

const headers = {
  "API-Key": `${process.env.REACT_APP_API_KEY}`,
  "Content-Type": "application/json",
};

export const loginUser = async (
  username: string,
  password: string,
  rememberMe?: boolean
) => {
  const data = {
    username,
    password,
  };

  try {
    let res = await axios.post(
      `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/users/authenticate`,
      data,
      {
        headers,
      }
    );

    if (rememberMe) {
      localStorage.setItem("JWT", res.data);
    } else {
      sessionStorage.setItem("JWT", res.data);
    }

    return { success: true };
  } catch (error: any) {
    return { success: false, error };
  }
};

export const logoutUser = (expired?: boolean) => {
  clearJWTFromStorage();
  if (expired) {
    showCustomNotification(
      "Logging out due to inactivity. Please login again."
    );
  }
  window.location.href = "/";
};

export const logoutIfTokenExpired = (token: string | null): boolean => {
  if (token) {
    if (!jwtHasValidExpiration(token)) {
      logoutUser(true);
      return true;
    }
  }
  return false;
};

export const getCurrentUserToken = (): string => {
  const token = getJWTFromLocalStorage();
  const isExpired = logoutIfTokenExpired(token);

  return isExpired ? "" : token ?? "";
};

export const getUserIdFromToken = () => {
  const token = getCurrentUserToken();
  if (!token) return "";

  return getConvertToUserToken(token)["https://id.about.golf/claims/v2/userId"];
};

export const useAuthenticateToken = (enabled: boolean) => {
  const dispatch = useAppDispatch();
  const userId = getUserIdFromToken();
  //const browserId = localStorage.getItem(LocalStorageItems.BROWSERID);

  const [authenticated, setAuthenticated] = useState<boolean>(false);

  useEffect(() => {
    if (!enabled) return;
    
    const hasJWT = localStorage.getItem("JWT") || sessionStorage.getItem("JWT");

    if (hasJWT) {
      dispatch(setIsAuthenticated({ isAuthenticated: true }));
      dispatch(setUserId({ userId }));
      
      let browserId = localStorage.getItem(LocalStorageItems.BROWSERID);
      if (!browserId) {
        const newBrowserId = uuidv4();
        localStorage.setItem(LocalStorageItems.BROWSERID, newBrowserId);
        window.browserId = newBrowserId;
      }
      setAuthenticated(true);
    } else {
      dispatch(setIsAuthenticated({ isAuthenticated: false }));
      setAuthenticated(false);
    }
  }, [enabled, dispatch, userId]);

  return authenticated;

  /*if (enabled) {
    if (localStorage.getItem("JWT") || sessionStorage.getItem("JWT")) {
      dispatch(setIsAuthenticated({ isAuthenticated: true }));
      dispatch(setUserId({ userId: userId }));
      if (!browserId) {
        localStorage.setItem(LocalStorageItems.BROWSERID, uuidv4());
        window.browserId = localStorage.getItem(LocalStorageItems.BROWSERID);
      }

      return true;
    } else {
      dispatch(setIsAuthenticated({ isAuthenticated: false }));
      return false;
    }
  }*/
};

export const useUpdateUserAgHandicap = (
  userId: string,
  aGHandicapValue: any
): [QueryState, RunMutation<EntitiesState, [AgLockerUpdateBody]>] => {
  const [
    updateAgHandicapByUserIdQueryState,
    updateAgHandicap,
  ] = useMutation((body: AgLockerUpdateBody) =>
    makeAgHandicapByUserIdMutation(userId, aGHandicapValue)
  );

  return [updateAgHandicapByUserIdQueryState, updateAgHandicap];
};
