import type { SluggedId } from "Models";
import { AdKitKey } from "types/enums";
import { z } from 'zod';
import { DateTime } from 'luxon';

const sluggedIdRegex = /^[123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz]{8}$/;
// eslint-disable-next-line no-control-regex
export const windowsFileNameXmlRegex = /^(?=.{1,100}$)(?!.*[<>:"/\\|?*\x00-\x1F]).+\.xml$/;
// eslint-disable-next-line no-control-regex
export const windowsFileNameImageRegex = /^(?=.{1,100}$)(?!.*[<>:"/\\|?*\x00-\x1F]).+\.(jpg|jpeg|png|bmp)$/;
export type AdKitKeyType = `${AdKitKey}` | keyof typeof AdKitKey;

export const DEFAULT_DATETIME = DateTime.fromMillis(0, { zone: 'utc' });

export function toSluggedId(value: string): SluggedId {
  return value as SluggedId;
}

export const holeBooleanMapSchema = z
  .object({
    "1": z.boolean(),
    "2": z.boolean(),
    "3": z.boolean(),
    "4": z.boolean(),
    "5": z.boolean(),
    "6": z.boolean(),
    "7": z.boolean(),
    "8": z.boolean(),
    "9": z.boolean(),
    "10": z.boolean(),
    "11": z.boolean(),
    "12": z.boolean(),
    "13": z.boolean(),
    "14": z.boolean(),
    "15": z.boolean(),
    "16": z.boolean(),
    "17": z.boolean(),
    "18": z.boolean(),
  })
  .strict(); // Ensures no extra keys are present


const nameValidator = z.string().max(100, { message: "Name must be 100 characters or fewer" });
const facilityIdValidator = z.string();
export const sluggedIdValidator = z.string().regex(sluggedIdRegex, { message: "Invalid SluggedId format" }).transform((val) => val as SluggedId);
/*const isoDateStringValidator = //z.string()
//.refine((val) => !isNaN(Date.parse(val)), { message: "Must be a valid ISO date", })
//.transform((val) => val as IsoDateString)*/

const isoDateStringValidator = z
.union([z.coerce.string(), z.coerce.date()])
.transform((input, ctx) => {
  const str = input instanceof Date ? input.toISOString() : input;
  const dt = DateTime.fromISO(str, { zone: 'utc' });
  if (!dt.isValid) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: `Invalid DateTime: ${dt.invalidExplanation}`,
    });
    return z.NEVER;
  }
  return dt;
});

z.coerce.date();
const descriptionValidator = z.union([
  z.string().max(1023, { message: "Description must be 1023 characters or fewer" }),
  z.null()
]).optional();

export const descriptionSchema = {
  description: descriptionValidator,
};

export const nameSchema = {
  name: nameValidator,
};

export const facilityIdSchema = {
  facilityId: facilityIdValidator,
};

export const sluggedIdSchema = {
  id: sluggedIdValidator,
};

  export const createdSchema = {
    created: isoDateStringValidator
  };

  export const lastModifiedSchema = {
    lastModified: isoDateStringValidator
  };

  export const deletionSchema = z.object({
    deleted: z.boolean(),
    // Allow whenDeleted to be an IsoDateString or null, and it’s optional
    whenDeleted: z.union([isoDateStringValidator, z.null()]).optional(),
  })
  .superRefine((data, ctx) => {
    if (data.deleted) {
      if (data.whenDeleted === undefined || data.whenDeleted === null) {
        ctx.addIssue({
          path: ['whenDeleted'],
          code: z.ZodIssueCode.custom,
          message: "whenDeleted must be provided when deleted is true",
        });
      }
    } else {
      if (data.whenDeleted !== undefined && data.whenDeleted !== null) {
        ctx.addIssue({
          path: ['whenDeleted'],
          code: z.ZodIssueCode.custom,
          message: "whenDeleted must not be provided when deleted is false",
        });
      }
    }
  });

  export const problemDetailsSchema = z.object({
    type: z.string().optional(),
    title: z.string().optional(),
    status: z.number().optional(),   
    detail: z.string().optional(),
    instance: z.string().optional(),
    extensions: z.record(z.union([z.unknown(), z.null()])),
  });
  export const validationProblemDetailsSchema = problemDetailsSchema.extend({
    title: z.string(),
    errors: z.record(z.array(z.string())),
  });

export type IError = {
  reasonse?: IError[];
  message: string;
  metadata?: Record<string, object>;
};

export const errorSchema: z.ZodType<IError> = z.lazy(() =>
  z.object({
    message: z.string(),
    metadata: z.record(z.any()),
    reasons: z.array(errorSchema),
  })
);

export const responseWithErrorsSchema = <TResponse>(
  resultSchema: z.ZodType<TResponse, z.ZodTypeDef, unknown>
) =>
  z.object({
    result: resultSchema,
    errors: z.array(errorSchema),
  });

  export type Validation = z.infer<typeof validationProblemDetailsSchema>;
  export type problem = z.infer<typeof problemDetailsSchema>;
  export type error = z.infer<typeof errorSchema>;

  export function createProblemDetailsSchema<TResponse>(
    schema: z.ZodType<TResponse, z.ZodTypeDef, unknown>
  ) {
    return z.union([
      schema,
      problemDetailsSchema,
      validationProblemDetailsSchema
    ]);
  };

  export function createProblemDetailsSchemaWithError<TResponse>(
    schema: z.ZodType<TResponse, z.ZodTypeDef, unknown>
  ) {
    return z.union([
      schema,
      problemDetailsSchema,
      validationProblemDetailsSchema,
      responseWithErrorsSchema(schema)
    ]);
  };

