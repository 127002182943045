import { RootState } from "StoreTypes";
import { styles } from "./Adkit.styles";
import { useSelector } from "react-redux";
import { useAppDispatch } from "utils/hooks";
import { ClubhouseAdKitDto } from "entities/responses/adkit";
import { getAllAdKits } from "./utils/adKitHelpers";
import { ModalType } from "utils/modals/modalHelpers";
import { AnimatePresence, motion } from "framer-motion";
import { useState, useEffect, useCallback } from "react";
import { useSidebarCollapsed } from "utils/ui/uiHelpers";
import { useFlags } from "launchdarkly-react-client-sdk";
import { modalAction } from "ui-modules/modals/modalSlice";
import { setSelectedAdKit } from "ui-modules/adkit/adKitSlice";
import { getCurrentFacility } from "modules/facility/facilitySelectors";
import { useMediaQuery, BreakPoints } from "utils/animations/animationHelper";
import { useGetCurrentLocalFacilityId } from "modules/facility/facilityHooks";
import AdKit from "./AdKit";
import Row from "components/ui/layout/Row";
import CreateNewAdkitModal from "./CreateNewAdkitModal";
import AdKitConfirmDeleteAlert from "./AdKitConfirmDelete";
import AdKitImageManagerModal from "./AdKitImageManagerModal";

const AdKitDashboard = () => {
  const dispatch = useAppDispatch();

  const isSidebarCollapsed = useSidebarCollapsed();
  const isDesktop = useMediaQuery(BreakPoints.LG);
  const currentFacilityId = useGetCurrentLocalFacilityId();

  const { adKit } = useFlags();
  const [allAdKits, setAllAdKits] = useState<ClubhouseAdKitDto[]>();

  const currentFacility = useSelector((state: RootState) =>
    getCurrentFacility(state)
  );

  const isAdKitModalOpen = useSelector(
    (state: RootState) =>
      state.modals?.modalSettings[ModalType.AdKitModal]?.isOpen
  );

  const isAdKitImageModalOpen = useSelector(
    (state: RootState) =>
      state.modals?.modalSettings[ModalType.AdKitImageManager]?.isOpen
  );

  const isAdKitDeleteModalOpen = useSelector(
    (state: RootState) =>
      state.modals?.modalSettings[ModalType.ConfirmDeleteAlert]?.isOpen
  );

  const setAdKitModal = () =>
    dispatch(modalAction({ isOpen: true, modalName: ModalType.AdKitModal }));

  const setAdKitImageModal = () =>
    dispatch(
      modalAction({ isOpen: true, modalName: ModalType.AdKitImageManager })
    );

  const setDeleteAdKitModal = () =>
    dispatch(
      modalAction({ isOpen: true, modalName: ModalType.ConfirmDeleteAlert })
    );

  const handleModalCallbacks = async (id: string): Promise<void> => {
    dispatch(
      setSelectedAdKit({
        selectedAdKit: {
          id: "",
          name: "",
          created: "",
        },
      })
    );
    await refreshAdKits();
  };

  const refreshAdKits = useCallback(async () => {
    getAllAdKits(currentFacility?.id).then((res) => {
      setAllAdKits(res);
    });
  }, [currentFacility?.id]);

  useEffect(() => {
    if (adKit) {
      refreshAdKits();
    }
  }, [adKit, currentFacility?.id, currentFacilityId, refreshAdKits]);

  return (
    <div
      className={`@container/lockerRoom lockerRoom w-full ${
        isDesktop
          ? isSidebarCollapsed
            ? "pl-24"
            : "pl-64"
          : "px-4 xl:pl-0 pt-8"
      }`}
    >
      {isAdKitModalOpen && (
        <CreateNewAdkitModal
          modalName={ModalType.AdKitModal}
          callback={handleModalCallbacks}
        />
      )}
      {isAdKitImageModalOpen && (
        <AdKitImageManagerModal modalName={ModalType.AdKitImageManager} />
      )}
      {isAdKitDeleteModalOpen && (
        <AdKitConfirmDeleteAlert
          modalName={ModalType.ConfirmDeleteAlert}
          confirmButtonText="Delete"
          cancelButtonText="Cancel"
          callback={handleModalCallbacks}
        />
      )}
      <Row cssClasses={`flex flex-col md:flex-row min-h-screen w-full mt-4`}>
        <AnimatePresence>
          <motion.div
            className={`w-full my-6 ml-0 sm:mx-8 @container/lockerroom`}
            key="adKitDashboard"
          >
            <div className="md:flex md:items-center md:justify-between">
              <div className="flex min-w-0">
                <div className="flex align-left flex-row items-center">
                  <button
                    onClick={() => setAdKitModal()}
                    className={styles.button}
                    data-testid="createAdKitModalButton"
                    disabled={
                      currentFacility?.capabilities?.canUpdateAdKit === false
                    }
                  >
                    Create New AdKit
                  </button>
                  <button
                    onClick={() => setAdKitImageModal()}
                    className={`${styles.button} ml-2`}
                    data-testid="createAdKitModalButton"
                    disabled={
                      currentFacility?.capabilities?.canUpdateAdKit === false
                    }
                  >
                    Manage AdKit Images
                  </button>
                  <small className="text-xs text-gray-400 dark:text-gray-50 ml-2"></small>
                </div>
              </div>
              <div className="mt-4 flex md:mt-0 md:ml-4 items-center justify-center"></div>
            </div>
            <Row cssClasses="pt-4">
              {allAdKits?.length === 0 ? (
                <div className="h-48 place-content-center p-4 text-center bg-white dark:bg-gray-800 rounded-lg shadow text-sm">
                  <p className="text-gray-500 dark:text-gray-400">
                    No AdKits found. Please click the 'Create New AdKit' button
                    to get started.
                  </p>
                </div>
              ) : (
                <div className={styles.leagueCardContainer}>
                  {allAdKits?.map((adkit: ClubhouseAdKitDto) => (
                    <AdKit
                      key={adkit.id}
                      adkit={adkit}
                      callback={setDeleteAdKitModal}
                    />
                  ))}
                </div>
              )}
            </Row>
          </motion.div>
        </AnimatePresence>
      </Row>
    </div>
  );
};
export default AdKitDashboard;
