import { motion } from "framer-motion";
import { styles } from "./Adkit.styles";
import { FiEdit2 } from "react-icons/fi";
import { ClubhouseAdKitDto } from "entities/responses/adkit";
import { TiDeleteOutline } from "react-icons/ti";
import { containerChildMotionVariants } from "utils/animations/animationHelper";
import { setSelectedAdKit } from "ui-modules/adkit/adKitSlice";
import { useAppDispatch } from "utils/hooks";
import { modalAction } from "ui-modules/modals/modalSlice";
import { ModalType } from "utils/modals/modalHelpers";

const AdKit: React.FC<AdKitProps> = (props) => {
  const { adkit, callback } = props;
  const dispatch = useAppDispatch();

  const setAdKitModal = () =>
    dispatch(modalAction({ isOpen: true, modalName: ModalType.AdKitModal }));

  async function handleEditAdKit(adKit: ClubhouseAdKitDto) {
    await dispatch(
      setSelectedAdKit({
        selectedAdKit: adKit,
      })
    );
    await setAdKitModal();
  }

  async function handleDeleteAdKit(adKit: ClubhouseAdKitDto) {
    dispatch(
      setSelectedAdKit({
        selectedAdKit: adKit,
      })
    );
    dispatch(
      modalAction({
        isOpen: true,
        modalName: ModalType.ConfirmDeleteAlert,
      })
    );
    callback();
  }

  return (
    <motion.div
      variants={containerChildMotionVariants}
      key={`adkit-${adkit?.id}`}
      className=""
    >
      <div
        className={`${styles.leagueCard} hover:shadow-lg hover:rounded-xl hover:border-brandDarkGray leagueRoundCard`}
      >
        <div className={styles.leagueCardHeaderRow}>
          <div className="w-full h-8">
            <span className={`${styles.leagueTitle} mb-2 mt-1`}>
              {adkit.name}
            </span>
          </div>
          <div
            className={`${styles.leagueEditDeleteContainer} leagueRoundCardEditDelete mr-2`}
          >
            <button
              type="button"
              className={`${styles.leagueDeleteButton}`}
              data-for={`delete-${adkit.name}`}
              data-tip={`delete ${adkit.name}`}
              onClick={() => handleDeleteAdKit(adkit)}
            >
              <TiDeleteOutline className={`size-5 mt-1 pt-0.5 text-red-500`} />
            </button>
          </div>
          <div
            className={`${styles.leagueEditDeleteContainer} leagueRoundCardEditDelete`}
          >
            <button
              type="button"
              className={`${styles.leagueEditButton}`}
              data-for={`edit-${adkit.name}`}
              data-tip={`Edit ${adkit.name}`}
              onClick={() => handleEditAdKit(adkit)}
            >
              <FiEdit2 className={`size-5 mt-1 pt-0.5 text-green-500`} />
            </button>
          </div>
        </div>
        <div
          className={`${styles.leagueCardRow} border-b border-gray-200 dark:border-zinc-900`}
        >
          <div className="h-8 overflow-hidden text-ellipsis brand-text">
            {adkit.description}
          </div>
        </div>
        <div className={styles.leagueCardHeaderRow}></div>
        <div className={styles.leagueCardDateRow}>
          <div className={styles.leagueCardCol}>
            <div className={styles.titleRow}>Created</div>
            <div className={styles.titleDescription}>
              {adkit.created.toLocaleString({
                month: "short",
                day: "2-digit",
                year: "numeric",
              })}
            </div>

            <div className={styles.titleDescription}></div>
          </div>
          <div className={styles.leagueCardCol}>
            <div className={styles.titleRow}>Last Modified</div>
            <div className={styles.dateDescription}>
              {adkit.lastModified &&
                adkit.lastModified.toLocaleString({
                  month: "short",
                  day: "2-digit",
                  year: "numeric",
                })}
            </div>
          </div>
        </div>

        <div className={`${styles.leagueCardDetailsRow}`}>
          <div className={styles.leagueCardCol}>
            <div className="">
              <button
                onClick={() => console.log("not implemented")}
                className="text-brandDarkGrey dark:text-gray-200 font-bold text-xs border-b border-gray-300 cursor-not-allowed"
              >
                View Details
              </button>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default AdKit;

type AdKitProps = {
  adkit: ClubhouseAdKitDto;
  callback: any;
};
