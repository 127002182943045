import { Round, StartRoundPayload, StartUnityGameDto } from "Models";
import { MoveRoundPayload, UseDataInfo } from "QueryTypes";
import { RunMutation, useMutation, useRequest } from "redux-query-react";
import { useMemo } from 'react';
import {
  getRoundByIdQuery,
  makeMoveRoundMutation,
  makeStartRoundMutation,
  makeStartUnityGameMutation,
} from "./roundQueries";
import { useSelector } from "react-redux";
import { RootState } from "StoreTypes";
import { EntitiesState, QueryState } from "redux-query";

export const useRoundById = (roundId: string): UseDataInfo<Round> => {

  const safeQuery = useMemo(() => {
      if (!roundId?.trim()) {
        //console.log('roundId is falsy or empty');
        return null;
      }
      return getRoundByIdQuery(roundId);
    }, [roundId]); // Add refreshKey to dependencies
  
  
  const [queryState, refreshRound] = useRequest(safeQuery ?? undefined);
  const roundById = useSelector(
    (state: RootState): Round => {
      return state.entities.rounds?.[roundId];
    }
  );

  return [ roundById, queryState, refreshRound ];
};

export const useStartRound = (
  facilityId: string,
  simulatorId: string
): [QueryState, RunMutation<EntitiesState, [StartRoundPayload]>] => {
  const [
    startRoundQueryState,
    startRound,
  ] = useMutation((body: StartRoundPayload) =>
    makeStartRoundMutation(facilityId, simulatorId, body)
  );
  return [startRoundQueryState, startRound];
};

export const useMoveRound = (
  roundId: string
): [QueryState, RunMutation<EntitiesState, [MoveRoundPayload]>] => {
  const [
    moveRoundQueryState,
    moveRound,
  ] = useMutation((body: MoveRoundPayload) =>
    makeMoveRoundMutation(roundId, body)
  );

  return [moveRoundQueryState, moveRound];
};

export const useStartUnityGame = (
  facilityId: string,
  simulatorId: string
): [QueryState, RunMutation<EntitiesState, [StartUnityGameDto]>] => {
  const [
    startUnityGameQueryState,
    startUnityGame,
  ] = useMutation((body: StartUnityGameDto) =>
    makeStartUnityGameMutation(facilityId, simulatorId, body)
  );
  return [startUnityGameQueryState, startUnityGame];
};
