import type { SluggedId, HoleBooleanMap } from "Models";
import { sluggedIdSchema, AdKitKeyType, sluggedIdValidator, holeBooleanMapSchema, windowsFileNameXmlRegex, windowsFileNameImageRegex, facilityIdSchema, descriptionSchema, createdSchema, lastModifiedSchema, deletionSchema, nameSchema } from "entities/common";
import { AdKitKey } from "types/enums";
import { z } from "zod";
import { DateTime } from 'luxon';

type ClubhouseAdKitLocationInstanceInternalDto = {
  id: SluggedId;
  imageId: SluggedId;
  holeNumber: number;
  instanceId: number;
  imageFilePath: string;
  imageUrl: string;
  widthAdjustment: number;
  heightAdjustment: number;
  created: DateTime;
  lastModified: DateTime;
  deleted: boolean;
  whenDeleted?: DateTime | null;
};

type ClubhouseAdKitLocationInternalDto = {
  id: SluggedId;
  adKitMetaDataId: AdKitKey;
  enabledHoles?: HoleBooleanMap;
  adInstances?: ClubhouseAdKitLocationInstanceInternalDto[];
  created: DateTime;
  lastModified: DateTime;
  deleted: boolean;
  whenDeleted?: DateTime | null;
};

type ClubhouseAdKitInternalDto = {
  id: SluggedId;
  name: string;
  description?: string | null;
  fileName: string;
  facilityId: string;
  transition: {
    display: number;
    fadeIn: number;
    fadeOut: number;
  };
  created: DateTime;
  lastModified: DateTime;
  deleted: boolean;
  whenDeleted?: DateTime | null;
  adLocations?: Partial<Record<AdKitKeyType, ClubhouseAdKitLocationInternalDto>>;
};


const ClubhouseAdKitAdLocationInstanceDtoBaseSchema = z.object({
  ...sluggedIdSchema,
  imageId: sluggedIdValidator,
  holeNumber: z.number().int().min(0).max(18),
  instanceId: z.number().int().min(-1),
  imageFilePath: z.string()
    .max(100, { message: "imageFilePath must be 100 characters or fewer" })
    .regex(windowsFileNameImageRegex, { message: "imageFilePath must be a valid Windows file name" }),
  imageUrl: z.string().url(),
  widthAdjustment: z.number().int().min(-1),
  heightAdjustment: z.number().int().min(-1),
  ...createdSchema,
  ...lastModifiedSchema,
});


  
const ClubhouseAdKitAdLocationInstanceDtoRawSchema = z.intersection(
  ClubhouseAdKitAdLocationInstanceDtoBaseSchema,
  deletionSchema
);


  const ClubhouseAdKitAdLocationDtoBaseSchema = z.object({
    ...sluggedIdSchema,
    adKitMetaDataId: z.nativeEnum(AdKitKey),
    enabledHoles: z.optional(holeBooleanMapSchema),
    adInstances: z.optional(z.array(ClubhouseAdKitAdLocationInstanceDtoRawSchema)),
    ...createdSchema,
    ...lastModifiedSchema,
  });
  
  const ClubhouseAdKitAdLocationDtoRawSchema = z.intersection(
    ClubhouseAdKitAdLocationDtoBaseSchema,
    deletionSchema
  );

  
  const ClubhouseAdKitAdLocationInternalDtoDictionarySchema = z.record(ClubhouseAdKitAdLocationDtoRawSchema);



const ClubhouseAdKitDtoBaseSchema = z.object({
    ...sluggedIdSchema,
    ...nameSchema,
    ...lastModifiedSchema,
    ...createdSchema,
    ...descriptionSchema,
    ...facilityIdSchema,
    fileName: z.string()
      .max(100, { message: "fileName must be 100 characters or fewer" })
      .regex(windowsFileNameXmlRegex, { message: "fileName must be a valid Windows file name and end in .xml" }),
    transition: z.object({
      display: z.number(),
      fadeIn: z.number(),
      fadeOut: z.number(),
    }),
    adLocations: z.optional(ClubhouseAdKitAdLocationInternalDtoDictionarySchema)
  });


  const ClubhouseAdKitDtoRawSchema = z.intersection(
    ClubhouseAdKitDtoBaseSchema,
    deletionSchema
  );

  export const ClubhouseAdKitLocationInstanceDtoSchema: z.ZodType<ClubhouseAdKitLocationInstanceInternalDto, z.ZodTypeDef, unknown> = ClubhouseAdKitAdLocationInstanceDtoRawSchema.transform(
    (data): ClubhouseAdKitLocationInstanceInternalDto => ({
      ...data,
      // Brand the id, created, lastModified and whenDeleted values
      whenDeleted: data.whenDeleted as DateTime | null | undefined,
    })
  );
  

  export const ClubhouseAdKitLocationDtoSchema: z.ZodType<ClubhouseAdKitLocationInternalDto, z.ZodTypeDef, unknown> = ClubhouseAdKitAdLocationDtoRawSchema.transform(
    (data): ClubhouseAdKitLocationInternalDto => ({
      ...data,
      // Brand the id, created, lastModified and whenDeleted values
      whenDeleted: data.whenDeleted as DateTime | null | undefined,
    })
  );


  export const ClubhouseAdKitDtoSchema: z.ZodType<ClubhouseAdKitInternalDto, z.ZodTypeDef, unknown> = ClubhouseAdKitDtoRawSchema.transform(
    (data): ClubhouseAdKitInternalDto => ({
      ...data,
      // Brand the id, created, lastModified and whenDeleted values
      whenDeleted: data.whenDeleted as DateTime | null | undefined,
    })
  );



  export type ClubhouseAdKitDto = z.infer<typeof ClubhouseAdKitDtoSchema>;
  export type ClubhouseAdKitLocationDto = z.infer<typeof ClubhouseAdKitLocationDtoSchema>;
  export type ClubhouseAdKitLocationInstanceDto = z.infer<typeof ClubhouseAdKitLocationInstanceDtoSchema>;




  export const ClubhouseAdKitDtoArraySchema: z.ZodType<
  ClubhouseAdKitDto[],
  z.ZodTypeDef,
  unknown
> = z.array(ClubhouseAdKitDtoSchema);
