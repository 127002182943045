import { RootState } from "StoreTypes";
import { useSelector } from "react-redux";
import { ImWarning } from "react-icons/im";
import { useAppDispatch } from "utils/hooks";
import { deleteAdKit } from "./utils/adKitHelpers";
import { ModalType } from "utils/modals/modalHelpers";
import { modalAction } from "ui-modules/modals/modalSlice";
import { setSelectedAdKit } from "ui-modules/adkit/adKitSlice";
import { useGetCurrentLocalFacilityId } from "modules/facility/facilityHooks";
import Row from "components/ui/layout/Row";
import Modal from "components/shared/Modal";

const AdKitConfirmDeleteAlert: React.FC<Props> = (props) => {
  const { confirmButtonText, cancelButtonText, callback, modalName } = props;
  const currentFacilityId = useGetCurrentLocalFacilityId();
  const dispatch = useAppDispatch();

  const selectedAdKit = useSelector(
    (state: RootState) => state.adkit?.selectedAdKit
  );

  const handleConfirm = async (e: React.SyntheticEvent<HTMLButtonElement>) => {
    await deleteAdKit(currentFacilityId, selectedAdKit?.id);
    if (callback) callback(true);
    setModalClose();
  };

  const handleCancel = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    setModalClose();
  };

  const setModalClose = () => {
    dispatch(
      setSelectedAdKit({
        selectedAdKit: {
          id: "",
          name: "",
          created: "",
        },
      })
    );
    dispatch(
      modalAction({
        isOpen: false,
        modalName: modalName,
      })
    );
  };

  return (
    <>
      <Modal
        hideFooter={true}
        hideHeader={true}
        modalName={ModalType.ConfirmDeleteAlert}
        modalSize="w-3/4 sm:w-1/2 md:w-2/3 xl:w-1/2 rounded-lg"
        body={
          <Row cssClasses="p-4">
            <Row cssClasses="flex flex-col text-md items-center justify-center my-4 text-center poppins">
              <div className="flex flex-none items-center justify-center w-16 h-16 rounded-full bg-brandGreen text-[0.625rem] font-medium text-white">
                <ImWarning className="h-10 w-10" />
              </div>
              <div className="flex pt-4 dark:text-gray-50">
                {`Do you want to delete ${selectedAdKit?.name}`}
              </div>
            </Row>
            <Row cssClasses="mt-6 text-center pr-1 flex flex-row w-full pt-2.5 text-xs items-center">
              <div className="flex w-1/2 m-auto">
                <button
                  onClick={(e) => handleConfirm(e)}
                  className="poppins py-2 px-4 bg-red-500 hover:bg-red-700 text-white rounded shadow hover:shadow-inner hover:text-white text-sm font-semibold w-full "
                  tabIndex={4}
                >
                  {confirmButtonText}
                </button>
              </div>

              {cancelButtonText && (
                <div className="flex w-1/2 ml-2">
                  <button
                    onClick={(e) => handleCancel(e)}
                    className="poppins py-2 px-4 bg-white-500 hover:bg-gray-700 text-black rounded shadow hover:shadow-inner hover:text-white text-sm font-semibold w-full dark:bg-gray-50 dark:hover:bg-gray-200 dark:text-zinc-800"
                    tabIndex={4}
                  >
                    {cancelButtonText}
                  </button>
                </div>
              )}
            </Row>
          </Row>
        }
      />
    </>
  );
};
export default AdKitConfirmDeleteAlert;

type Props = {
  title?: string;
  modalName: string;
  callback?: any;
  confirmButtonText?: string;
  cancelButtonText?: string;
  showModal?: boolean;
  confirmed?: boolean;
};

AdKitConfirmDeleteAlert.defaultProps = {
  title: "",
  confirmButtonText: "Confirm",
  showModal: false,
  confirmed: false,
};
