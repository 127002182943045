import {
  getUserIdFromToken,
  useFacilitiesByUserId,
  useSetCurrentLocalFacilityId,
} from "modules/facility/facilityHooks";
import FacilityList from "./FacilityList";
import Row from "./Row";
import { useSelector } from "react-redux";
import { RootState } from "StoreTypes";
import { useEffect } from "react";

const FacilitiesDropDown = () => {
  const currentFacilityId = useSelector(
    (state: RootState) => state.facility.currentFacilityId
  );

  const userId = () => getUserIdFromToken();
  const [userFacilities] = useFacilitiesByUserId(userId());

  useEffect(() => {
    console.log("Current Facility ID changed to:", currentFacilityId);
  }, [currentFacilityId]);

  const enableSetFacilityId =
    userFacilities.length && !currentFacilityId ? true : false;

  const facilityIdToSet = userFacilities[0]?.id || "";
  useSetCurrentLocalFacilityId(facilityIdToSet, enableSetFacilityId);

  return (
    <>
      {userFacilities.length ? (
        <Row cssClasses="flex">
          {userFacilities.length > 1 ? (
            <FacilityList userFacilities={userFacilities} />
          ) : (
            <Row cssClasses="flex items-center align-center font-bold poppins text-brandDarkGrey dark:text-gray-200">
              {userFacilities[0]?.name}
            </Row>
          )}
        </Row>
      ) : null}
    </>
  );
};
export default FacilitiesDropDown;
