import { FeatureFlag } from "EntityTypes";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "StoreTypes";
import { useAppDispatch } from "utils/hooks";
import { setCurrentFacility } from "ui-modules/facility-control/facilityControlSlice";
import { FacilityNormalized } from "EntityTypes";
import { getSessionStorageValue } from "utils/hooks/localStorage";
import { getCurrentUserToken } from "modules/user";
import jwt_decode from "jwt-decode";
import { getUserFacilitiesQuery } from "./facilityQueries";
import { UseDataInfo } from "QueryTypes";
import { useRequest } from "redux-query-react";
import { useEffect } from "react";

export const useCurrentFacilityFeatureFlags = (): FeatureFlag => {
  const currentFacilityId = useGetCurrentLocalFacilityId();
  return useSelector(
    (state: RootState): FeatureFlag => {
      return state.entities.facilities?.[currentFacilityId]?.featureFlags;
    }
  );
};

export const useCurrentSelectedFacilityId = async (state: RootState) => {
  const currentLocalFacilityId = useGetCurrentLocalFacilityId();
  const dispatch = useAppDispatch();
  if (state.facility.currentFacilityId) return state.facility.currentFacilityId;
  await dispatch(
    setCurrentFacility({ currentFacility: currentLocalFacilityId })
  );
  return state.facility.currentFacilityId;
};

export const useGetCurrentLocalFacilityId = (): string => {
  return getSessionStorageValue("facilityId")
    ? getSessionStorageValue("facilityId")
    : "";
};
export const useSetCurrentLocalFacilityId = (
  facilityId: string,
  enabled: boolean
) => {
  //console.log("useSetCurrentLocalFacilityId - ", facilityId, enabled);
  const dispatch = useDispatch();
  useEffect(() => {
    if (enabled) {
      dispatch(setCurrentFacility({ currentFacility: facilityId }));
    }
  }, [dispatch, facilityId, enabled]);
};

export const getUserIdFromToken = () => {
  const token = getCurrentUserToken();
  if (!token) return "";
  const decoded: UserToken = jwt_decode(token);

  return decoded["https://id.about.golf/claims/v2/userId"];
};

export const useFacilitiesByUserId = (
  userId: string
): UseDataInfo<FacilityNormalized[]> => {
  const [queryState, refreshFacilities] = useRequest(
    getUserFacilitiesQuery(userId)
  );

  const userFacilities = useSelector(
    (state: RootState): FacilityNormalized[] => {
      if (!state.entities?.facilities) return [];
      return Object.values(state.entities?.facilities);
    }
  );

  return [userFacilities, queryState, refreshFacilities];
};
